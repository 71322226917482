import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import ReactStars from 'react-stars'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { MdDeleteOutline } from 'react-icons/md'
import { HiPencilSquare } from 'react-icons/hi2'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { SlBookOpen } from 'react-icons/sl'
import { GiTeacher } from 'react-icons/gi'
import { MdOutlineSubject } from 'react-icons/md'
import FilterConfigModal from './FilterConfig'
import EditChapter from './EditChapter'
import { FormControl } from 'react-bootstrap'

const MainHome = (props) => {
  const contentRef = useRef()
  const {
    course,
    streams,
    syllabus,
    language,
    Student_Name,
    isAdmin,
    classss,
  } = props
  const [loading, setLoading] = useState()
  const [classes, setClassess] = useState()
  const [categories, setCategories] = useState()
  const [subjects, setSubjects] = useState()
  const [tutors, setTutors] = useState()
  const [chapters, setChapters] = useState()
  const [selectedClass, setSelectedClass] = useState()
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedSubjects, setSelectedSubjects] = useState()
  const [selectedTutors, setSelectedTutors] = useState()
  const navigate = useNavigate()
  const [classs, setClasss] = useState()
  const [show, setShow] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [subjectLoading, setSubjectLoading] = useState(false)
  const [selectedChapters, setSelectedChapters] = useState()
  const [searchKey, setSearchKey] = useState('')

  let { classId, catId, subId, tutId } = useParams()

  useEffect(() => {
    if (course && streams && syllabus && language && !classes) {
      setLoading(true)
      callToBackend(
        `content/content-list/?language=${language?.value}&course=${course?.value}&stream=${streams?.value}&syllabus=${syllabus?.value}`,
      ).then((cls) => {
        setClassess(cls)
        if (!!cls?.length) {
          if (classId) {
            getCategories(cls, false)
          } else if (classss) {
            getCategories(cls, false)
          } else {
            getCategories(cls[0])
          }
        } else {
          setLoading(false)
        }
      })
    }
    // eslint-disable-next-line
  }, [course, streams, syllabus, language])

  const getCategories = (clsss, isNew) => {
    let cls =
      !isNew && classId
        ? clsss?.find((d) => d?.Class_Name === classId)
        : classss
        ? clsss?.find((d) => d?.Class_Name === classss?.value)
        : clsss
    setSelectedClass(cls)
    setClasss({ value: cls?.Class_Name, label: cls?.Class_Name })
    callToBackend(
      `/content/category-list/?language=${language?.value}&course=${course?.value}&stream=${streams?.value}&syllabus=${syllabus?.value}&class=${cls?.Class_Name}`,
    ).then((category) => {
      setCategories(category)
      if (!!category?.length) {
        getContents(category[0], cls, isNew, category)
        setDataLoading(false)
      } else {
        setLoading(false)
        setDataLoading(false)
      }
    })
  }

  const getCategoriesSpecific = (clsss, isNew) => {
    let cls = clsss
    setSelectedClass(clsss)
    setClasss({ value: cls?.Class_Name, label: cls?.Class_Name })

    callToBackend(
      `/content/category-list/?language=${language?.value}&course=${course?.value}&stream=${streams?.value}&syllabus=${syllabus?.value}&class=${cls?.Class_Name}`,
    ).then((category) => {
      setCategories(category)
      if (!!category?.length) {
        getContents(category[0], cls, isNew, category)
        setDataLoading(false)
      } else {
        setLoading(false)
        setDataLoading(false)
      }
    })
  }

  const getContents = (catgs, cls, isNew, categories) => {
    let catg =
      !isNew && catId
        ? categories?.find((d) => d?.Category === Number(catId))
        : catgs

    setSelectedCategory(catg)
    setSubjectLoading(true)
    callToBackend(
      `content/subject-list/${catg?.Category}/${cls?.Class_Name}/${language?.value}/`,
    ).then((subject) => {
      setSubjects(subject?.subject_data)
      setTutors(
        subject?.tutors?.sort(
          (a, b) => Number(a?.Tutor__Priority) - Number(b?.Tutor__Priority),
        ),
      )

      if (classId && catId && subId && tutId && !isNew) {
        setSelectedSubjects(
          subject?.subject_data.find((d) => d?.Subject__Subject === subId),
        )
        let selectedTut = subject?.tutors
          ?.sort(
            (a, b) => Number(a?.Tutor__Priority) - Number(b?.Tutor__Priority),
          )
          ?.filter((d) => d?.Subject__Subject === subId)
          ?.filter((ds) => ds?.Tutor === Number(tutId))[0]

        setSelectedTutors(selectedTut)
        setChapters(subject?.chapter_data)
        handleNavigate(cls?.Class_Name, catg?.Category, subId, tutId)
      } else {
        setSelectedSubjects(subject?.subject_data[0])
        let selectedTut = subject?.tutors
          ?.sort(
            (a, b) => Number(a?.Tutor__Priority) - Number(b?.Tutor__Priority),
          )
          ?.filter(
            (d) =>
              d?.Subject__Subject ===
              subject?.subject_data[0]?.Subject__Subject,
          )
        setSelectedTutors(selectedTut[0])
        setChapters(subject?.chapter_data)

        handleNavigate(
          cls?.Class_Name,
          catg?.Category,
          subject?.subject_data[0]?.Subject__Subject,
          selectedTut?.Tutor,
        )
      }
      setLoading(false)
      setSubjectLoading(false)
    })
  }

  const handleNavigate = (clsId, catId, subId, tutId) => {
    navigate(`/home/${clsId}/${catId}/${subId}/${tutId}`)
  }

  let tutorsss = tutors?.filter(
    (tu) => tu?.Subject__Subject === selectedSubjects?.Subject__Subject,
  )

  let chaptersssss = chapters
    ?.filter((ch) => ch?.Chapter__Content_Id__Tutor === selectedTutors?.Tutor)
    .filter(
      (ds) =>
        ds?.Chapter__Content_Id__Subject__Subject ===
        selectedSubjects?.Subject__Subject,
    )

  let chaptersss = chaptersssss?.filter((d) =>
    searchKey?.length > 0
      ? d?.Chapter__Chapter?.toLowerCase()?.includes(searchKey?.toLowerCase())
      : d,
  )

  var result = {}
  chapters
    ?.filter(
      (ds) =>
        ds?.Chapter__Content_Id__Subject__Subject ===
        selectedSubjects?.Subject__Subject,
    )
    ?.forEach((element) => {
      if (result[element.Chapter__Content_Id__Tutor]) {
        result[element.Chapter__Content_Id__Tutor] += element.topic_count
      } else {
        result[element.Chapter__Content_Id__Tutor] = element.topic_count
      }
    })

  const handleConmfirmDelete = (ev, item, itemSelectd) => {
    ev.stopPropagation()
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete the selected item?</p>
            <button onClick={onClose} className="cancel-btn">
              No
            </button>
            <button
              className="submit-btn"
              onClick={() => {
                if (itemSelectd === 'subject') {
                  handleSubjectDelete(item)
                  onClose()
                }
                if (itemSelectd === 'tutor') {
                  handleTutorDelete(item)
                  onClose()
                }
                if (itemSelectd === 'chapter') {
                  handleChapterDelete(item)
                  onClose()
                }
              }}
            >
              Yes, Do it!
            </button>
          </div>
        )
      },
    })
  }

  const handleSubjectDelete = (sub) => {
    let body = {
      selectedClass: selectedClass?.Class_Name,
      selectedCategory: selectedCategory?.Category,
      selectedSubject: sub.Subject__Subject,
      isActive: false,
    }
    callToBackend(`content/content-details/`, 'PATCH', body).then(() => {
      window.location.reload()
    })
  }

  const handleTutorDelete = (tut) => {
    let body = {
      selectedClass: selectedClass?.Class_Name,
      selectedCategory: selectedCategory?.Category,
      selectedSubject: selectedSubjects.Subject__Subject,
      selectedTutor: tut?.Tutor__Tutor_Name,
      isActive: false,
    }
    callToBackend(`content/tutor-details/`, 'PATCH', body).then(() => {
      window.location.reload()
    })
  }

  const handleChapterDelete = (chap) => {
    let body = {
      isActive: false,
      id: chap?.Chapter__id,
    }
    callToBackend(`content/chapter-details/`, 'PATCH', body).then(() => {
      window.location.reload()
    })
  }

  if (loading) {
    return (
      <div
        style={{
          height: '80%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Triangle
          height="180"
          width="180"
          color="var(--highlight-primary)"
          ariaLabel="triangle-loading"
          visible={true}
        />
      </div>
    )
  }

  let classLength = classes?.filter((d) => d?.Class_Name !== null)

  return (
    <div className="home-cont" id="main">
      <div className="home-contents-cont" ref={contentRef}>
        <div className="contents-left">
          <div className="title-cont">
            <span className="header">
              Hello,{' '}
              <span style={{ fontWeight: 600, color: 'var(--gradient-2)' }}>
                {Student_Name}
              </span>
            </span>
            <span className="sub-header">
              Nice to have you back, what an exciting day!
            </span>
            <span className="sub-header">
              Get ready and continue your lessons today.
            </span>
          </div>

          {!!classLength?.length && (
            <div className="cards-cont">
              {classes?.map((d) => (
                <div
                  className={clsx('cards ', {
                    'active-cards': d?.Class_Name === selectedClass?.Class_Name,
                  })}
                  onClick={() => {
                    getCategoriesSpecific(d, true)
                    handleNavigate(
                      d?.Class_Name,
                      selectedCategory?.Category,
                      selectedSubjects?.Subject__Subject,
                      selectedTutors?.Tutor,
                    )
                  }}
                >
                  <div className="cards-title">{d?.Class_Name}</div>
                  <div className="details-cont">
                    <span className="details">{d?.Subject_Count} Subjects</span>
                    <span className="details">{d?.Tutor_Count} Tutors</span>
                    <span className="details">{d?.Chapter_Count} Chapters</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {!!classLength?.length ? (
          <div className="contents-right">
            {dataLoading && (
              <div
                style={{
                  height: '80%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Triangle
                  height="80"
                  width="80"
                  color="var(--highlight-primary)"
                  ariaLabel="triangle-loading"
                  visible={true}
                />
              </div>
            )}

            {!dataLoading && (
              <>
                <div className="class-select-cont">
                  <Select
                    options={classes?.map((d) => {
                      return { value: d?.Class_Name, label: d?.Class_Name }
                    })}
                    onChange={(ev) => {
                      setClasss(ev)
                      getCategoriesSpecific({ Class_Name: ev.value }, true)
                      handleNavigate(
                        ev.value,
                        selectedCategory?.Category,
                        selectedSubjects?.Subject__Subject,
                        selectedTutors?.Tutor,
                      )
                    }}
                    value={classs}
                    placeholder="Select Class"
                  />
                </div>

                <div className="tabs-cont">
                  {categories
                    ?.sort(
                      (a, b) =>
                        Number(a?.Category__Priority) -
                        Number(b?.Category__Priority),
                    )
                    ?.map((d) => (
                      <div
                        onClick={() => {
                          getContents(d, selectedClass, true)
                          setSearchKey('')
                          handleNavigate(
                            selectedClass?.Class_Name,
                            d?.Category,
                            selectedSubjects?.Subject__Subject,
                            selectedTutors?.Tutor,
                          )
                        }}
                        className={clsx(
                          'tabs',
                          selectedCategory?.Category === d?.Category
                            ? 'active-tabs'
                            : '',
                        )}
                      >
                        {d?.Category__Category}
                      </div>
                    ))}
                </div>

                {subjectLoading && (
                  <div
                    style={{
                      margin: '2rem',
                    }}
                  >
                    <Triangle
                      height="80"
                      width="80"
                      color="var(--highlight-primary)"
                      ariaLabel="triangle-loading"
                      visible={true}
                    />
                  </div>
                )}

                {!subjectLoading && (
                  <div className="secondary-cont ">
                    <div className="title">
                      <SlBookOpen className="title-icon" />
                      Subjects
                    </div>
                    <div className="subjects-cont">
                      {subjects?.map((d) => (
                        <div
                          className={clsx(
                            'cards subject-subject-cards',
                            selectedSubjects?.Subject__Subject ===
                              d?.Subject__Subject
                              ? 'active-cards'
                              : '',
                          )}
                          onClick={() => {
                            setSelectedSubjects(d)
                            setSearchKey('')
                            setSelectedTutors(
                              tutors?.filter(
                                (ds) =>
                                  ds?.Subject__Subject === d?.Subject__Subject,
                              )[0],
                            )

                            handleNavigate(
                              selectedClass?.Class_Name,
                              selectedCategory?.Category,
                              d?.Subject__Subject,
                              tutors?.filter(
                                (ds) =>
                                  ds?.Subject__Subject === d?.Subject__Subject,
                              )[0]?.Tutor,
                            )
                          }}
                        >
                          <div className="cards-title">
                            <span>{d?.Subject__Subject}</span>
                            {isAdmin && (
                              <MdDeleteOutline
                                onClick={(ev) =>
                                  handleConmfirmDelete(ev, d, 'subject')
                                }
                                className="delete-icon"
                              />
                            )}
                          </div>
                          <div className="details-cont">
                            <span className="details">
                              {d?.total_tutors} Tutors
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="title">
                      <GiTeacher className="title-icon" />
                      Tutors
                    </div>

                    <div className="subjects-cont">
                      {tutorsss
                        ?.sort(
                          (a, b) =>
                            Number(a?.Tutor__Priority) -
                            Number(b?.Tutor__Priority),
                        )
                        ?.map((d) => (
                          <div
                            className={clsx(
                              'cards',
                              selectedTutors?.Tutor__Tutor_Name ===
                                d?.Tutor__Tutor_Name
                                ? 'active-cards'
                                : '',
                            )}
                            onClick={() => {
                              setSelectedTutors(d)
                              setSearchKey('')
                              handleNavigate(
                                selectedClass?.Class_Name,
                                selectedCategory?.Category,
                                selectedSubjects?.Subject__Subject,
                                d?.Tutor,
                              )
                            }}
                          >
                            <div
                              className="cards-title cards-title-tutor"
                              style={{ marginBottom: '0.5rem' }}
                            >
                              <GiTeacher className="cards-title-icon" />

                              <span>{d?.Tutor__Tutor_Name}</span>
                              {isAdmin && (
                                <MdDeleteOutline
                                  onClick={(ev) =>
                                    handleConmfirmDelete(ev, d, 'tutor')
                                  }
                                  className="delete-icon"
                                />
                              )}
                            </div>
                            <div className="details-cont">
                              <span className="details">
                                {
                                  chapters
                                    ?.filter(
                                      (ch) =>
                                        ch?.Chapter__Content_Id__Tutor ===
                                        d?.Tutor,
                                    )
                                    .filter(
                                      (ds) =>
                                        ds?.Chapter__Content_Id__Subject__Subject ===
                                        selectedSubjects?.Subject__Subject,
                                    ).length
                                }{' '}
                                Chapters
                              </span>
                              <span className="details">
                                {result[d?.Tutor]} Topics
                              </span>
                            </div>
                            <ReactStars
                              count={5}
                              size={15}
                              color2={'var(--gradient-1)'}
                              value={4}
                              edit={false}
                            />
                          </div>
                        ))}
                    </div>

                    <div className="title">
                      <MdOutlineSubject className="title-icon" />
                      Chapters
                    </div>
                    <div className="search-cont" style={{ width: '30%' }}>
                      <FormControl
                        type="text"
                        placeholder="Search Chapters..."
                        onChange={(ev) => {
                          setSearchKey(ev.target.value)
                        }}
                        value={searchKey}
                      />
                    </div>
                    <div className="subjects-cont">
                      {chaptersss?.map((d) => (
                        <div
                          className={clsx('cards chapter-cards')}
                          onClick={() => {
                            navigate(
                              `/content/${d?.Chapter__Chapter}/${d?.Chapter__id}`,
                            )
                          }}
                        >
                          <div
                            className="cards-title"
                            style={{ marginBottom: '0.5rem' }}
                          >
                            <span>{d?.Chapter__Chapter}</span>
                            {isAdmin && (
                              <MdDeleteOutline
                                onClick={(ev) =>
                                  handleConmfirmDelete(ev, d, 'chapter')
                                }
                                className="delete-icon"
                              />
                            )}
                            {isAdmin && (
                              <HiPencilSquare
                                className="delete-icon"
                                onClick={(ev) => {
                                  ev.stopPropagation()
                                  setShow(true)
                                  setSelectedChapters(d)
                                }}
                              />
                            )}
                          </div>
                          <div className="details-cont">
                            <span className="details">
                              {d?.topic_count} Topics
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="contents-right no-data-contents">
            <img
              src={require('../../assets/images/no-data.jpg')}
              alt=""
              className="no-data-logo"
            />
            <div className="no-data">
              We don't have any content for this configuration please change
              configuration.
            </div>
          </div>
        )}
      </div>

      <FilterConfigModal show={show} onClose={() => setShow(false)}>
        <div className="admin-cont" style={{ padding: '0' }}>
          <EditChapter
            selectedChapters={selectedChapters}
            onClose={() => {
              setShow(false)
            }}
          />
        </div>
      </FilterConfigModal>
    </div>
  )
}

export default MainHome
