import React, { useEffect, useState } from 'react'
import { FormControl, Table } from 'react-bootstrap'
import { MdOutlineDeleteSweep, MdContentCopy } from 'react-icons/md'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert'

const CreateTutors = () => {
  const [course, setCourse] = useState('')
  const [allCourses, setAllCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [priority, setPriority] = useState()

  useEffect(() => {
    getCourse()
  }, [])

  const getCourse = () => {
    setLoading(true)
    callToBackend('tutors/tutor-list/', 'GET').then((data) => {
      setAllCourses(data)
      setLoading(false)
    })
  }

  const [copySuccess, setCopySuccess] = useState('')
  const [copiedIndex, setCopyIndex] = useState('')

  // your function to copy here

  const copyToClipBoard = async (copyMe, index) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopySuccess('Copied!')
      setCopyIndex(index)

      setTimeout(() => {
        setCopySuccess('')
        setCopyIndex(undefined)
      }, 2000)
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
  }

  return (
    <div className="admin-contents-cont">
      <div className="masters-cont">
        <div className="master-contents-cont">
          <div className="forms-cont">
            <div className="form-group">
              <FormControl
                type="text"
                onChange={(ev) => setCourse(ev.target.value)}
                value={course}
                placeholder="Enter Tutor"
              />
            </div>
            <div className="form-group">
              <FormControl
                type="text"
                onChange={(ev) => setPriority(ev.target.value)}
                value={priority}
                placeholder="Enter Priority"
              />
            </div>
            <button
              className="button-add"
              disabled={course.length === 0}
              onClick={() => {
                let body = { Tutor_Name: course, Priority: priority }
                callToBackend('tutors/tutor-list/', 'POST', body)
                  .then((d) => {
                    getCourse()
                    setCourse('')
                  })
                  .catch(() => setLoading(false))
              }}
            >
              Add
            </button>
          </div>

          {loading ? (
            <div style={{ margin: '2rem' }}>
              <Triangle
                height="80"
                width="80"
                color="var(--highlight-primary)"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            !!allCourses?.length && (
              <div className="table-cont-2">
                <Table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>SL : NO</th>
                      <th>Tutor</th>
                      <th>Priority</th>
                      <th>Tutor Code</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCourses.map((d, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{d?.Tutor_Name}</td>
                        <td>{d?.Priority}</td>
                        <td>
                          <div>
                            {d?.Tutor_Code}
                            <MdContentCopy
                              onClick={() =>
                                copyToClipBoard(d?.Tutor_Code, index)
                              }
                              style={{ marginLeft: '1rem' }}
                            />
                          </div>
                          {copiedIndex === index && (
                            <span
                              style={{
                                fontSize: '1rem',
                                color: 'var(--highlight-green)',
                              }}
                            >
                              {copySuccess}
                            </span>
                          )}
                        </td>
                        <td>
                          <MdOutlineDeleteSweep
                            onClick={() => {
                              confirmAlert({
                                customUI: ({ onClose }) => {
                                  return (
                                    <div className="custom-ui">
                                      <h1>Are you sure?</h1>
                                      <p>
                                        You want to delete the selected item?
                                      </p>
                                      <button
                                        onClick={onClose}
                                        className="cancel-btn"
                                      >
                                        No
                                      </button>
                                      <button
                                        className="submit-btn"
                                        onClick={() => {
                                          onClose()

                                          if (d?.id) {
                                            callToBackend(
                                              `tutors/tutor-details/${d?.id}/`,
                                              'DELETE',
                                            ).then((d) => {
                                              getCourse()
                                              onClose()
                                            })
                                            let data = allCourses.filter(
                                              (ds, ind) => {
                                                if (ind === index) {
                                                  return null
                                                } else return ds
                                              },
                                            )

                                            setAllCourses(data)
                                          } else {
                                            let data = allCourses.filter(
                                              (ds, ind) => {
                                                if (ind === index) {
                                                  return null
                                                } else return ds
                                              },
                                            )

                                            setAllCourses(data)
                                          }
                                        }}
                                      >
                                        Yes, Do it!
                                      </button>
                                    </div>
                                  )
                                },
                              })
                            }}
                            size={24}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )
          )}

          {/* {!!allCourses.length && (
            <div className="btn-cont">
              <button className="submit-btn" onClick={() => handleSubmit()}>
                Submit
              </button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default CreateTutors
