import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Student_Pone_Number, studentId } from '../Home/Home'
import { callToBackend } from '../../utils/api'
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import stateCities from '../../utils/stateCitiesData.json'
import Select from 'react-select'
import { Triangle } from 'react-loader-spinner'
import { toast } from 'react-toastify'

const Profile = () => {
  const [studentData, setStudentData] = useState()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    callToBackend(`student/student-details/${Student_Pone_Number}/`).then(
      (da) => {
        setStudentData(da)
        setLoading(false)
      },
    )
  }, [])

  const submit = () => {
    setLoading(true)
    let body = {
      Student_Name: studentData.Student_Name,
      Present_School_College: studentData?.Present_School_College,
      Student_Phone_Number: studentData?.Student_Phone_Number,
      Country: studentData?.Country,
      State: studentData?.State,
      District: studentData?.District,
      User: studentData?.User,
    }

    callToBackend(`student/student-details/${studentId}/`, 'PUT', body)
      .then((da) => {
        setStudentData(da)
        setLoading(false)
        toast.success('Successfully updated user details')
      })
      .catch((e) => {
        setLoading(false)
        toast.error('Failed to update user details.')
      })
  }

  return (
    <div className="profile-cont">
      <Header showChapterName={false} chapterName={false} />
      <div className="profile-sub-cont">
        {loading ? (
          <div
            style={{
              height: '80%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Triangle
              height="60"
              width="60"
              color="var(--highlight-primary)"
              ariaLabel="triangle-loading"
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="heading">My Profile</div>
            <div className="heading">{Student_Pone_Number}</div>
            <Form onSubmit={submit} className="form-cont">
              <Form.Group size="lg" className="form-group">
                <label htmlFor="">Student Name</label>
                <Form.Control
                  type="text"
                  value={studentData?.Student_Name}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      Student_Name: e.target.value,
                    })
                  }
                  placeholder="Enter Student Name"
                  autoComplete="new-password"
                />
              </Form.Group>
              <Form.Group size="lg" className="form-group">
                <label htmlFor="">Present School/College Name</label>
                <Form.Control
                  type="text"
                  value={studentData?.Present_School_College}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      Present_School_College: e.target.value,
                    })
                  }
                  placeholder="Enter Student Name"
                  autoComplete="new-password"
                />
              </Form.Group>

              <Form.Group size="lg" className="form-group">
                <label htmlFor="">State</label>
                <Select
                  options={stateCities.states.map((d) => {
                    return { value: d.state, label: d.state }
                  })}
                  onChange={(e) => {
                    setStudentData({
                      ...studentData,
                      State: e.value,
                    })
                  }}
                  value={{
                    value: studentData?.State,
                    label: studentData?.State,
                  }}
                  placeholder="Select State"
                  maxMenuHeight={150}
                />
              </Form.Group>

              <Form.Group size="lg" className="form-group">
                <label htmlFor="">District</label>
                <Select
                  options={stateCities.states
                    .filter((d) => d.state === studentData?.State)[0]
                    ?.districts.map((d) => {
                      return { value: d, label: d }
                    })}
                  onChange={(e) => {
                    setStudentData({
                      ...studentData,
                      District: e.value,
                    })
                  }}
                  value={{
                    value: studentData?.District,
                    label: studentData?.District,
                  }}
                  placeholder="Select District"
                  maxMenuHeight={150}
                />
              </Form.Group>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 'auto',
                }}
              >
                <Button className="auth-btn" type="submit" onClick={submit}>
                  Submit
                </Button>
                <Button
                  className="logout-btn"
                  onClick={() => {
                    localStorage.clear()
                    navigate('/')
                    window.location.reload()
                  }}
                >
                  Logout
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
    </div>
  )
}

export default Profile
