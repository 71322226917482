import clsx from 'clsx'
import React, { useState } from 'react'
import CreateCourse from './CreateCourse'
import CreateStreams from './CreateStreams'
import CreateSyllabus from './CreateSyllabus'
import CreateCategory from './CreateCategory'
import CreateSubjects from './CreateSubject'
import CreateClasses from './CreateClasses'

const masters = [
  'Create Course',
  'Create Streams',
  'Create Syllabus',
  'Create Category',
  'Create Classes',
  'Create Subjects',
]

const CreateMasters = () => {
  const [selectedCategory, setSelectedCategory] = useState('Create Course')

  return (
    <div className="admin-contents-cont">
      <div className="masters-cont">
        <div className="tabs-cont">
          {masters?.map((d) => (
            <div
              onClick={() => setSelectedCategory(d)}
              className={clsx(
                'tabs',
                selectedCategory === d ? 'active-tabs' : '',
              )}
            >
              {d}
            </div>
          ))}
        </div>

        {selectedCategory === 'Create Course' && <CreateCourse />}
        {selectedCategory === 'Create Streams' && <CreateStreams />}
        {selectedCategory === 'Create Syllabus' && <CreateSyllabus />}
        {selectedCategory === 'Create Category' && <CreateCategory />}
        {selectedCategory === 'Create Classes' && <CreateClasses />}
        {selectedCategory === 'Create Subjects' && <CreateSubjects />}
      </div>
    </div>
  )
}

export default CreateMasters
