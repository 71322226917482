import React, { useEffect, useState } from 'react'
import { FormControl, Table } from 'react-bootstrap'
import { MdOutlineDeleteSweep, MdContentCopy } from 'react-icons/md'
import Select from 'react-select'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert'

const CreateSyllabus = () => {
  const [allCourses, setAllCourses] = useState([])
  const [allStreams, setAllStreams] = useState([])
  const [allSyllabus, setAllSyllabus] = useState([])
  const [allCategory, setAllCategory] = useState([])
  const [allClasses, setAllClasses] = useState([])
  const [course, setCourse] = useState()
  const [streams, setStreams] = useState()
  const [syllabus, setSyllabus] = useState()
  const [category, setCategory] = useState()
  const [classes, setClasses] = useState()
  const [subject, setSubject] = useState()
  const [allSubjects, setAllSubjects] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (!allCourses?.length) {
      getCourse()
    }

    if (!!allCourses?.length && !allStreams?.length) {
      getStreams()
    }

    if (!!allCourses?.length && !!allStreams?.length && !allSyllabus.length) {
      getSyllabus()
    }

    if (!!allSyllabus.length && !allCategory.length) {
      getCategory()
    }

    if (!!allCategory.length && !allClasses.length) {
      getClasses()
    }

    if (!!allClasses.length) {
      getSubjects()
    }

    // eslint-disable-next-line
  }, [allCourses, allStreams, allSyllabus, allCategory, allClasses])

  const getCourse = () => {
    callToBackend('courses/course-list/', 'GET').then((data) => {
      setAllCourses(data)
    })
  }

  const getStreams = () => {
    callToBackend('streams/stream-list/', 'GET').then((data) => {
      setAllStreams(data)
    })
  }

  const getSyllabus = () => {
    callToBackend('syllabus/syllabus-list/', 'GET').then((data) => {
      setAllSyllabus(data)
    })
  }

  const getCategory = () => {
    callToBackend('main-category/main-category-list/', 'GET').then((data) => {
      setAllCategory(data)
    })
  }

  const getClasses = () => {
    callToBackend('classes/class-list/', 'GET').then((data) => {
      setAllClasses(data)
    })
  }

  const getSubjects = () => {
    setLoading(true)
    callToBackend('subjects/subject-list/', 'GET')
      .then((data) => {
        let datas = data.map((ds) => {
          return {
            course: {
              value: ds?.Course,
              label: allCourses.find((dss) => dss?.id === ds?.Course)?.Course,
            },
            stream: {
              value: ds?.Stream,
              label: allStreams.find((dss) => dss?.id === ds?.Stream)?.Stream,
            },
            syllabus: {
              value: ds?.Stream,
              label: allSyllabus.find((dss) => dss?.id === ds?.Syllabus)
                ?.Syllabus,
            },
            category: {
              value: ds?.Category,
              label: allCategory.find((dss) => dss?.id === ds?.Category)
                ?.Category,
            },
            classes: {
              value: ds?.Class,
              label: allClasses.find((dss) => dss?.id === ds?.Class)?.Class,
            },
            subject: ds?.Subject,
            id: ds?.id,
            ...ds,
          }
        })

        setAllSubjects(datas)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const [copySuccess, setCopySuccess] = useState('')
  const [copiedIndex, setCopyIndex] = useState('')

  // your function to copy here

  const copyToClipBoard = async (copyMe, index) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopySuccess('Copied!')
      setCopyIndex(index)

      setTimeout(() => {
        setCopySuccess('')
        setCopyIndex(undefined)
      }, 2000)
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
  }

  return (
    <div className="master-contents-cont">
      <div className="forms-cont">
        <div className="form-group">
          <Select
            options={allCourses.map((d) => {
              return {
                value: d?.id,
                label: d?.Course + ' (' + d?.Course_Code + ')',
              }
            })}
            onChange={setCourse}
            value={course}
            placeholder="Course"
          />
        </div>
        <div className="form-group">
          <Select
            options={allStreams
              ?.filter((d) => d?.Course === course?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Stream + ' (' + d?.Stream_Code + ')',
                }
              })}
            onChange={setStreams}
            value={streams}
            placeholder="Streams"
            disabled={!course}
          />
        </div>
        <div className="form-group">
          <Select
            options={allSyllabus
              ?.filter((d) => d?.Stream === streams?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Syllabus + ' (' + d?.Syllabus_Code + ')',
                }
              })}
            onChange={setSyllabus}
            value={syllabus}
            placeholder="Syllabus"
            disabled={!course}
          />
        </div>

        <div className="form-group">
          <Select
            options={allCategory
              ?.filter((d) => d?.Syllabus === syllabus?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Category + ' (' + d?.Category_Code + ')',
                }
              })}
            onChange={setCategory}
            value={category}
            placeholder="Category"
            disabled={!course}
          />
        </div>
        <div className="form-group">
          <Select
            options={allClasses
              ?.filter((d) => d?.Category === category?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Class + ' (' + d?.Class_Code + ')',
                }
              })}
            onChange={setClasses}
            value={classes}
            placeholder="Classes"
            disabled={!course}
          />
        </div>
        <div className="form-group">
          <FormControl
            type="text"
            onChange={(ev) => setSubject(ev.target.value)}
            value={subject}
            placeholder="Enter Subject"
          />
        </div>
        <button
          className="button-add"
          disabled={course?.length === 0 || streams?.length === 0}
          onClick={() => {
            let body = {
              Course: course?.value,
              Stream: streams?.value,
              Syllabus: syllabus?.value,
              Category: category?.value,
              Class: classes?.value,
              Subject: subject,
            }
            callToBackend('subjects/subject-list/', 'POST', body).then(
              (data) => {
                getSubjects()
                setSubject('')
              },
            )
          }}
        >
          Add
        </button>
      </div>

      {loading ? (
        <div style={{ margin: '2rem' }}>
          <Triangle
            height="80"
            width="80"
            color="var(--highlight-primary)"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        !!allSubjects?.length && (
          <div className="table-cont">
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <th>SL : NO</th>
                  <th>Course Name</th>
                  <th>Stream Name</th>
                  <th>Syllabus Name Name</th>
                  <th>Category Name</th>
                  <th>Class Name</th>
                  <th>Subject Name</th>
                  <th>Subject Code</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allSubjects.map((d, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{d?.course?.label}</td>
                    <td>{d?.stream?.label}</td>
                    <td>{d?.syllabus?.label}</td>
                    <td>{d?.category?.label}</td>
                    <td>{d?.classes?.label}</td>
                    <td>{d?.subject}</td>
                    <td>
                      <div>
                        {d?.Subject_Code}
                        <MdContentCopy
                          onClick={() =>
                            copyToClipBoard(d?.Subject_Code, index)
                          }
                          style={{ marginLeft: '1rem' }}
                        />
                      </div>
                      {copiedIndex === index && (
                        <span
                          style={{
                            fontSize: '1rem',
                            color: 'var(--highlight-green)',
                          }}
                        >
                          {copySuccess}
                        </span>
                      )}
                    </td>
                    <td>
                      <MdOutlineDeleteSweep
                        onClick={() => {
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              return (
                                <div className="custom-ui">
                                  <h1>Are you sure?</h1>
                                  <p>You want to delete the selected item?</p>
                                  <button
                                    onClick={onClose}
                                    className="cancel-btn"
                                  >
                                    No
                                  </button>
                                  <button
                                    className="submit-btn"
                                    onClick={() => {
                                      onClose()

                                      if (d?.id) {
                                        callToBackend(
                                          `subjects/subject-details/${d?.id}/`,
                                          'DELETE',
                                        ).then((d) => {
                                          getSubjects()
                                          onClose()
                                        })
                                        let data = allSubjects.filter(
                                          (ds, ind) => {
                                            if (ind === index) {
                                              return null
                                            } else return ds
                                          },
                                        )

                                        setAllSubjects(data)
                                      } else {
                                        let data = allSubjects.filter(
                                          (ds, ind) => {
                                            if (ind === index) {
                                              return null
                                            } else return ds
                                          },
                                        )

                                        setAllSubjects(data)
                                      }
                                    }}
                                  >
                                    Yes, Do it!
                                  </button>
                                </div>
                              )
                            },
                          })
                        }}
                        size={24}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )
      )}

      {/* {!!allSubjects.length && (
        <div className="btn-cont">
          <button className="submit-btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )} */}
    </div>
  )
}

export default CreateSyllabus
