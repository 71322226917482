import React, { useState } from 'react'
import Login from './Login'
import Register from './RegisterForm'

const Auth = () => {
  const [resigetr, setRegister] = useState(false)
  const [phoneNumber, setphoneNumber] = useState('')

  return (
    <div className="auth-cont">
      <div className="auth-left-cont" />
      <div className="auth-right-cont">
        {!resigetr ? (
          <Login
            setRegister={setRegister}
            phoneNumber={phoneNumber}
            setphoneNumber={setphoneNumber}
          />
        ) : (
          <Register setRegister={setRegister} setphoneNumber={setphoneNumber} />
        )}
      </div>
    </div>
  )
}

export default Auth
