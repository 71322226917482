import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { phoneRegex } from './Login'
import { callToBackendUnAuth } from '../../utils/api'
import { toast } from 'react-toastify'
import { Triangle } from 'react-loader-spinner'
import Select from 'react-select'
import stateCities from '../../utils/stateCitiesData.json'

export default function Register(props) {
  const [studentName, setStudentName] = useState('')
  const [mobile, setmobile] = useState('')
  // const navigate = useNavigate()
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({ value: -1, label: 'Select State' })
  const [city, setCity] = useState({ value: -1, label: 'Select District' })
  const [schoolCollegeName, setSchoolCollegeName] = useState('')

  function validateForm() {
    return (
      studentName.length > 0 &&
      mobile.length > 0 &&
      !invalidPhoneNumber &&
      state.value !== -1 &&
      city.value !== -1
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    let body = {
      username: mobile,
      password1: 's#1234' + mobile + 's#1234',
      password2: 's#1234' + mobile + 's#1234',
    }
    callToBackendUnAuth('api/v1/rest-auth/registration/', 'POST', body)
      .then((d) => {
        let body2 = {
          Student_Name: studentName,
          Student_Phone_Number: mobile,
          State: state?.value,
          District: city?.value,
          Key: d?.Key,
          Present_School_College: schoolCollegeName,
        }
        callToBackendUnAuth('student/student-list/', 'POST', body2)
          .then(() => {
            callToBackendUnAuth(`student/student-details/${mobile}/`).then(
              (data) => {
                localStorage.setItem('user-token', d.key)
                localStorage.setItem('user-details', JSON.stringify(data))
                props.setRegister(false)
                props.setphoneNumber(mobile)
                toast.success('Logged in successfully.')
                setLoading(false)
              },
            )
          })
          .catch(() => {
            toast.error('Failed to register student name.')
            setLoading(false)
          })
      })
      .catch(() => {
        toast.error('Failed to register.')
        setLoading(false)
      })
  }

  return (
    <div className="auth-form-cont">
      <Form
        className="form-cont register-form-cont"
        onSubmit={(ev) => handleSubmit(ev)}
      >
        <div className="logo-cont">
          <img
            src={require('../../assets/images/logo-crop.png')}
            alt=""
            className="logo"
          />
          <img
            src={require('../../assets/images/slogan.png')}
            alt=""
            className="slogan"
          />
        </div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="var(--highlight-primary)"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="header">
              Welcome,{' '}
              <span style={{ fontWeight: 600, color: 'var(--gradient-1)' }}>
                Register
              </span>
            </div>
            <Form.Group size="lg" className="form-group">
              <Form.Control
                type="text"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                placeholder="Enter Student Name"
                autoComplete="new-password"
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group">
              <span className="prefix">+91</span>
              <Form.Control
                type="text"
                value={mobile}
                onChange={(e) => {
                  setmobile(e.target.value)
                  if (e.target.value.match(phoneRegex)) {
                    setInvalidPhoneNumber(false)
                  } else {
                    setInvalidPhoneNumber(true)
                  }
                }}
                placeholder="Enter Phone Number"
                autoComplete="new-password"
                className="form-control-mobile"
                maxLength={10}
              />

              {mobile.length > 0 && invalidPhoneNumber && (
                <small className="warning">
                  Please enter valid phone number.
                </small>
              )}
            </Form.Group>

            <Form.Group size="lg" className="form-group">
              <Form.Control
                type="text"
                value={schoolCollegeName}
                onChange={(e) => setSchoolCollegeName(e.target.value)}
                placeholder="Enter School/College Name"
                autoComplete="new-password"
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group">
              <Select
                options={stateCities.states.map((d) => {
                  return { value: d.state, label: d.state }
                })}
                onChange={(e) => setState(e)}
                value={{
                  value: state?.value,
                  label: state?.label,
                }}
                placeholder="Select State"
                maxMenuHeight={150}
              />
            </Form.Group>

            <Form.Group size="lg" className="form-group">
              <Select
                options={stateCities.states
                  .filter((d) => d.state === state?.value)[0]
                  ?.districts.map((d) => {
                    return { value: d, label: d }
                  })}
                onChange={(e) => setCity(e)}
                value={{
                  value: city?.value,
                  label: city?.label,
                }}
                placeholder="Select District"
                maxMenuHeight={150}
              />
            </Form.Group>

            <div style={{ width: '100%' }}>
              <Button
                disabled={!validateForm()}
                className="auth-btn"
                type="submit"
                onClick={handleSubmit}
              >
                Register
              </Button>
            </div>

            <div className="new-user">
              <span className="content">
                Already User ?{' '}
                <span
                  className="highlight"
                  onClick={() => props.setRegister(false)}
                >
                  Login
                </span>{' '}
              </span>
            </div>
          </>
        )}
      </Form>
    </div>
  )
}
