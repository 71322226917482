import React from 'react'
import Modal from 'react-bootstrap/Modal'

function FilterConfigModal(props) {
  const { show, onClose, children, width } = props

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      className="body--dark edit-chapter-modal"
      centered
    >
      <Modal.Body style={{ padding: '3rem 1rem', width: width ? width : '' }}>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default FilterConfigModal
