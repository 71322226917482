import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const ProtectedRoute = (props) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkUserToken = () => {
    const userToken = localStorage.getItem('user-token')
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(true)
      return navigate('/auth/login')
    }
    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkUserToken()
    // eslint-disable-next-line
  }, [isLoggedIn])

  return (
    <React.Fragment>
      {isLoggedIn ? (
        <>
          {props.children}
          <div>
            <span className="share-your-feedback">Share your feedback</span>
            <FloatingWhatsApp
              phoneNumber="+918088203437"
              accountName="ApnaTcher"
              avatar={require('../assets/images/logo.png')}
              style={{
                height: '40px',
                width: '40px',
              }}
            />
          </div>
        </>
      ) : null}
    </React.Fragment>
  )
}

export default ProtectedRoute
