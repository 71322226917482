import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import MainHome from './MainHome'
import HomeConfiguration from './HomeConfiguration'
import FilterConfigModal from './FilterConfig'
import { callToBackend } from '../../utils/api'

const userDetails = localStorage.getItem('user-details')
export const Student_Name = JSON.parse(userDetails)?.Student_Name
export const Student_Pone_Number = JSON.parse(userDetails)?.Student_Phone_Number
export const studentId = JSON.parse(userDetails)?.id

export const isAdmin =
  Student_Pone_Number === '9663881439' || Student_Pone_Number === '8553322965'

const Home = () => {
  let isConfigureScreen = localStorage.getItem('isConfigured') === null

  const [course, setCourse] = useState()
  const [streams, setStreams] = useState()
  const [syllabus, setSyllabus] = useState()
  const [classs, setClass] = useState()
  const [language, setLanguage] = useState()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!Student_Pone_Number) {
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('isConfigured') !== null) {
      let course = JSON.parse(localStorage.getItem('course'))
      let stream = JSON.parse(localStorage.getItem('streams'))
      let syllabus = JSON.parse(localStorage.getItem('syllabus'))
      let classs = JSON.parse(localStorage.getItem('classs'))
      let language = JSON.parse(localStorage.getItem('language'))
      setCourse(course)
      setStreams(stream)
      setSyllabus(syllabus)
      setClass(classs)
      setLanguage(language)
    }
  }, [])

  const handleSubmitConfiguration = (ev) => {
    ev.preventDefault()
    localStorage.setItem('isConfigured', 'true')
    localStorage.setItem('course', JSON.stringify(course))
    localStorage.setItem('streams', JSON.stringify(streams))
    localStorage.setItem('syllabus', JSON.stringify(syllabus))
    localStorage.setItem('classs', JSON.stringify(classs))
    localStorage.setItem('language', JSON.stringify(language))

    let body = {
      Student_Profile: studentId,
      Current_Course: course?.value,
      Current_Stream: streams?.value,
      Current_Syllabus: syllabus?.value,
      Current_Language: language?.value,
      Current_Class: classs?.value,
    }

    callToBackend(
      `student/student-default-course-list/`,
      'POST',
      body,
    ).then((d) => window.location.reload())
  }

  if (!Student_Pone_Number) return <div></div>

  return (
    <>
      <Header
        setShow={setShow}
        isConfigureScreen={isConfigureScreen}
        isAdmin={isAdmin}
        course={course}
        streams={streams}
        syllabus={syllabus}
        language={language}
      />
      {isConfigureScreen ? (
        <HomeConfiguration
          handleSubmitConfiguration={handleSubmitConfiguration}
          course={course}
          setCourse={setCourse}
          streams={streams}
          setStreams={setStreams}
          syllabus={syllabus}
          setSyllabus={setSyllabus}
          language={language}
          setLanguage={setLanguage}
          classs={classs}
          setClass={setClass}
        />
      ) : (
        <MainHome
          course={course}
          streams={streams}
          syllabus={syllabus}
          language={language}
          Student_Name={Student_Name}
          isAdmin={isAdmin}
          classss={classs}
        />
      )}

      <FilterConfigModal show={show} onClose={() => setShow(false)}>
        <HomeConfiguration
          handleSubmitConfiguration={handleSubmitConfiguration}
          course={course}
          setCourse={setCourse}
          streams={streams}
          setStreams={setStreams}
          syllabus={syllabus}
          setSyllabus={setSyllabus}
          language={language}
          setLanguage={setLanguage}
          isFromModal={true}
          classs={classs}
          setClass={setClass}
          setShow={setShow}
        />
      </FilterConfigModal>
    </>
  )
}

export default Home
