import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { callToBackendUnAuth } from '../../utils/api'
import { toast } from 'react-toastify'
import { Triangle } from 'react-loader-spinner'

export const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

export default function Login(props) {
  const { phoneNumber, setphoneNumber } = props
  const navigate = useNavigate()
  const [invalidphoneNumber, setInvalidphoneNumber] = useState(false)
  const [loading, setLoading] = useState(false)

  function validateForm() {
    return phoneNumber.length > 0 && !invalidphoneNumber
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    let body = {
      username: phoneNumber,
      password: 's#1234' + phoneNumber + 's#1234',
    }
    callToBackendUnAuth('api/v1/rest-auth/login/', 'POST', body)
      .then((d) => {
        callToBackendUnAuth(`student/student-details/${phoneNumber}/`).then(
          (data) => {
            localStorage.setItem('user-token', d.key)
            localStorage.setItem('user-details', JSON.stringify(data))
            navigate('/')
            toast.success('Logged in successfully.')
            setLoading(false)
          },
        )
      })
      .catch(() => {
        toast.error('Failed to login , Please enter valid phone number')
        setLoading(false)
      })
  }

  return (
    <div className="auth-form-cont">
      <Form
        className="form-cont login-form-cont"
        onSubmit={(ev) => handleSubmit(ev)}
      >
        <div className="logo-cont">
          <img
            src={require('../../assets/images/logo-crop.png')}
            alt=""
            className="logo"
          />
          <img
            src={require('../../assets/images/slogan.png')}
            alt=""
            className="slogan"
          />
        </div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="var(--highlight-primary)"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="header">
              Welcome,{' '}
              <span style={{ fontWeight: 600, color: 'var(--gradient-1)' }}>
                Login
              </span>
            </div>
            <Form.Group
              size="lg"
              className="form-group"
              style={{ width: '100%' }}
            >
              <Form.Label>Phone Number *</Form.Label>
              <span className="prefix" style={{ top: '4.5rem' }}>
                +91
              </span>

              <Form.Control
                autoFocus
                type="text"
                value={phoneNumber}
                onChange={(e) => {
                  setphoneNumber(e.target.value)
                  if (e.target.value.match(phoneRegex)) {
                    setInvalidphoneNumber(false)
                  } else {
                    setInvalidphoneNumber(true)
                  }
                }}
                placeholder="Enter Phone Number"
                autoComplete="nope"
                className="form-control-mobile"
                maxLength={10}
              />
              {phoneNumber.length > 0 && invalidphoneNumber && (
                <small
                  className="warning"
                  style={{ fontSize: '1.2rem', fontWeight: 600 }}
                >
                  Please enter valid phone number.
                </small>
              )}
            </Form.Group>

            <Button
              block
              disabled={!validateForm()}
              className="auth-btn"
              onClick={(ev) => {
                handleSubmit(ev)
              }}
              type="submit"
            >
              Login
            </Button>

            <div className="new-user">
              <span className="content">
                New User ?{' '}
                <span
                  className="highlight"
                  onClick={() => props.setRegister(true)}
                >
                  Register
                </span>{' '}
              </span>
            </div>
          </>
        )}
      </Form>
    </div>
  )
}
