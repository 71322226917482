import React, { useEffect, useState } from 'react'
import { callToBackend } from '../../utils/api'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import { Triangle } from 'react-loader-spinner'

const HomeConfiguration = (props) => {
  const {
    course,
    setCourse,
    streams,
    setStreams,
    syllabus,
    setSyllabus,
    language,
    setLanguage,
    handleSubmitConfiguration,
    isFromModal,
    setShow,
    setClass,
    classs,
  } = props
  const [allCourses, setAllCourses] = useState([])
  const [allStreams, setAllStreams] = useState([])
  const [allSyllabus, setAllSyllabus] = useState([])
  const [allLanguages, setallLanguages] = useState([])
  const [allClass, setAllClass] = useState([])
  const [loadingContent, setLoadingContent] = useState(false)

  useEffect(() => {
    getCourse()
  }, [])

  useEffect(() => {
    if (!!allCourses?.length && !allStreams?.length) {
      getStreams()
    }

    if (!!allCourses?.length && !!allStreams?.length && !allSyllabus.length) {
      getSyllabus()
    }

    // eslint-disable-next-line
  }, [allCourses, allStreams, allSyllabus])

  const getCourse = () => {
    callToBackend('courses/course-list/', 'GET').then((data) => {
      setAllCourses(data)
    })
  }

  const getStreams = () => {
    callToBackend('streams/stream-list/', 'GET').then((data) => {
      setAllStreams(data)
    })
  }

  const getSyllabus = () => {
    callToBackend('syllabus/syllabus-list/', 'GET').then((data) => {
      setAllSyllabus(data)
    })
  }

  const getLanguages = (syllabuss) => {
    callToBackend(
      `/language/course-based-language-list/?course=${course?.value}&stream=${streams?.value}&syllabus=${syllabuss?.value}`,
      'GET',
    ).then((data) => {
      setallLanguages(data)
    })
  }

  useEffect(() => {
    if (course && streams && syllabus && !allLanguages.length) {
      getLanguages(syllabus)
    }
    // eslint-disable-next-line
  }, [course, streams, syllabus])

  useEffect(() => {
    if (
      language &&
      language?.value !== -1 &&
      course &&
      streams &&
      syllabus &&
      !allClass.length
    ) {
      getContent(language)
    }
    // eslint-disable-next-line
  }, [language, course, streams, syllabus])

  const getContent = (languages) => {
    setLoadingContent(true)
    callToBackend(
      `content/content-list/?language=${languages?.value}&course=${course?.value}&stream=${streams?.value}&syllabus=${syllabus?.value}`,
    ).then((cls) => {
      let data = cls.filter((d) => d?.Class_Name !== null)
      setAllClass(data)
      setLoadingContent(false)
    })
  }

  const disabled =
    !course ||
    !syllabus ||
    !streams ||
    !language ||
    !classs ||
    streams?.value === -1 ||
    syllabus?.value === -1 ||
    language?.value === -1 ||
    classs?.value === -1

  return (
    <div className="home-configuration-cont">
      {!isFromModal && <div className="left-cont" />}
      <Form
        onSubmit={handleSubmitConfiguration}
        className="content-dropdowns"
        style={isFromModal ? { width: '100%' } : {}}
      >
        <h6>Content Configuration</h6>
        <span className="sub-header">
          Set your <b>Course </b>,<b>Stream </b>,<b>Syllabus </b>
          and <b>Language </b> to start your classes today.
        </span>
        <div className="main-form-group">
          <div className="form-group">
            <Select
              options={allCourses.map((d) => {
                return { value: d?.id, label: d?.Course }
              })}
              onChange={(ev) => {
                setCourse(ev)
                setStreams({ value: -1, label: 'Select Strem' })
                setSyllabus({ value: -1, label: 'Select Syllabus' })
                setLanguage({ value: -1, label: 'Select Language' })
                setClass({ value: -1, label: 'Select Class' })
              }}
              value={course}
              placeholder="Select Course"
            />
          </div>
          <div className="form-group">
            <Select
              options={allStreams
                ?.filter((d) => d?.Course === course?.value)
                ?.map((d) => {
                  return { value: d?.id, label: d?.Stream }
                })}
              onChange={(ev) => {
                setStreams(ev)
                setSyllabus({ value: -1, label: 'Select Syllabus' })
                setLanguage({ value: -1, label: 'Select Language' })
                setClass({ value: -1, label: 'Select Class' })
              }}
              value={streams}
              placeholder="Select Stream"
              disabled={!course}
            />
          </div>
          <div className="form-group">
            <Select
              options={allSyllabus
                ?.filter((d) => d?.Stream === streams?.value)
                ?.map((d) => {
                  return { value: d?.id, label: d?.Syllabus }
                })}
              onChange={(ev) => {
                setSyllabus(ev)
                getLanguages(ev)
                setLanguage({ value: -1, label: 'Select Language' })
                setClass({ value: -1, label: 'Select Class' })
              }}
              value={syllabus}
              placeholder="Select Syllabus"
              disabled={!streams}
            />
          </div>
          <div className="form-group">
            <Select
              options={allLanguages?.map((d) => {
                return { value: d?.id, label: d?.Language }
              })}
              onChange={(ev) => {
                setLanguage(ev)
                getContent(ev)
                setClass({ value: -1, label: 'Select Class' })
              }}
              value={language}
              placeholder="Select Teaching Language"
            />
          </div>
          {loadingContent ? (
            <div
              style={{
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Triangle
                height="60"
                width="60"
                color="var(--highlight-primary)"
                ariaLabel="triangle-loading"
                visible={true}
              />
            </div>
          ) : (
            language &&
            language?.value !== -1 && (
              <>
                {allClass?.length > 0 ? (
                  <div className="form-group">
                    <Select
                      options={allClass?.map((d) => {
                        return { value: d?.Class_Name, label: d?.Class_Name }
                      })}
                      onChange={setClass}
                      value={classs}
                      placeholder="Select Class"
                    />
                  </div>
                ) : (
                  (language || language?.value !== -1) && (
                    <span className="no-data">
                      Currently we don't have any classes for this
                      configuration.
                    </span>
                  )
                )}
              </>
            )
          )}
        </div>

        <div className="btn-cont">
          <button
            className="submit-btn"
            disabled={disabled}
            onClick={handleSubmitConfiguration}
          >
            Submit Configuration
          </button>
          {isFromModal && (
            <button className="cancel-btn" onClick={() => setShow(false)}>
              Cancel
            </button>
          )}
        </div>
      </Form>
    </div>
  )
}

export default HomeConfiguration
