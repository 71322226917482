import axios from 'axios'

export const URL_BACKEND = 'https://g242n.pythonanywhere.com/'

export const callToBackendUnAuth = async (ENDPOINT, METHOD, DATA) => {
  const options = {
    url: `${URL_BACKEND}${ENDPOINT}`,
    method: METHOD,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    data: DATA,
  }

  const response = await axios(options)
  return response.data
}

// Create Function to handle requests from the backend
export const callToBackend = async (ENDPOINT, METHOD, DATA) => {
  const options = {
    url: `${URL_BACKEND}${ENDPOINT}`,
    method: METHOD,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    data: DATA,
  }

  axios.defaults.headers.common[
    'Authorization'
  ] = `Token ${localStorage.getItem('user-token')}`
  const response = await axios(options)
  return response.data
}

// Then you make a call with the exact endpoint and method:
// const response = await this.callToBackend('createSetupIntent', 'POST');
// console.log(JSON.stringify(response));
