import React, { useEffect, useState } from 'react'
import { FormControl, Table } from 'react-bootstrap'
import { MdOutlineDeleteSweep, MdContentCopy } from 'react-icons/md'
import Select from 'react-select'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert'

const CreateSyllabus = () => {
  const [allCourses, setAllCourses] = useState([])
  const [allStreams, setAllStreams] = useState([])
  const [course, setCourse] = useState()
  const [streams, setStreams] = useState()
  const [syllabus, setSyllabus] = useState()
  const [allSyllabus, setAllSyllabus] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (!allCourses?.length) {
      getCourse()
    }

    if (!!allCourses?.length && !allStreams?.length) {
      getStreams()
    }

    if (!!allCourses?.length && !!allStreams?.length) {
      getSyllabus()
    }

    // eslint-disable-next-line
  }, [allCourses, allStreams])

  const getCourse = () => {
    callToBackend('courses/course-list/', 'GET').then((data) => {
      setAllCourses(data)
    })
  }

  const getStreams = () => {
    callToBackend('streams/stream-list/', 'GET').then((data) => {
      setAllStreams(data)
    })
  }

  const getSyllabus = () => {
    setLoading(true)
    callToBackend('syllabus/syllabus-list/', 'GET').then((data) => {
      let datas = data.map((ds) => {
        return {
          course: {
            value: ds?.Course,
            label: allCourses.find((dss) => dss?.id === ds?.Course)?.Course,
          },
          stream: {
            value: ds?.Stream,
            label: allStreams.find((dss) => dss?.id === ds?.Stream)?.Stream,
          },
          syllabus: ds.Syllabus,
          id: ds?.id,
          ...ds,
        }
      })
      setAllSyllabus(datas)
      setLoading(false)
    })
  }

  const [copySuccess, setCopySuccess] = useState('')
  const [copiedIndex, setCopyIndex] = useState('')

  // your function to copy here

  const copyToClipBoard = async (copyMe, index) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopySuccess('Copied!')
      setCopyIndex(index)

      setTimeout(() => {
        setCopySuccess('')
        setCopyIndex(undefined)
      }, 2000)
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
  }

  return (
    <div className="master-contents-cont">
      <div className="forms-cont">
        <div className="form-group">
          <Select
            options={allCourses.map((d) => {
              return {
                value: d?.id,
                label: d?.Course + ' (' + d?.Course_Code + ')',
              }
            })}
            onChange={setCourse}
            value={course}
            placeholder="Select Course"
          />
        </div>
        <div className="form-group">
          <Select
            options={allStreams
              ?.filter((d) => d?.Course === course?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Stream + ' (' + d?.Stream_Code + ')',
                }
              })}
            onChange={setStreams}
            value={streams}
            placeholder="Select Streams"
            disabled={!course}
          />
        </div>
        <div className="form-group">
          <FormControl
            type="text"
            onChange={(ev) => setSyllabus(ev.target.value)}
            value={syllabus}
            placeholder="Please Enter Syllabus"
          />
        </div>
        <button
          className="button-add"
          disabled={course?.length === 0 || streams?.length === 0}
          onClick={() => {
            let body = {
              Course: course?.value,
              Stream: streams?.value,
              Syllabus: syllabus,
            }
            callToBackend('syllabus/syllabus-list/', 'POST', body).then(
              (data) => {
                getSyllabus()
                setCourse('')
                setStreams('')
                setSyllabus('')
              },
            )
          }}
        >
          Add
        </button>
      </div>

      {loading ? (
        <div style={{ margin: '2rem' }}>
          <Triangle
            height="80"
            width="80"
            color="var(--highlight-primary)"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        !!allSyllabus?.length && (
          <div className="table-cont">
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <th>SL : NO</th>
                  <th>Course Name</th>
                  <th>Stream Name</th>
                  <th>Syllabus Name</th>
                  <th>Syllabus Code</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allSyllabus.map((d, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{d?.course?.label}</td>
                    <td>{d?.stream?.label}</td>
                    <td>{d?.syllabus}</td>
                    <td>
                      <div>
                        {d?.Syllabus_Code}
                        <MdContentCopy
                          onClick={() =>
                            copyToClipBoard(d?.Syllabus_Code, index)
                          }
                          style={{ marginLeft: '1rem' }}
                        />
                      </div>
                      {copiedIndex === index && (
                        <span
                          style={{
                            fontSize: '1rem',
                            color: 'var(--highlight-green)',
                          }}
                        >
                          {copySuccess}
                        </span>
                      )}
                    </td>
                    <td>
                      <MdOutlineDeleteSweep
                        onClick={() => {
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              return (
                                <div className="custom-ui">
                                  <h1>Are you sure?</h1>
                                  <p>You want to delete the selected item?</p>
                                  <button
                                    onClick={onClose}
                                    className="cancel-btn"
                                  >
                                    No
                                  </button>
                                  <button
                                    className="submit-btn"
                                    onClick={() => {
                                      onClose()

                                      if (d?.id) {
                                        callToBackend(
                                          `syllabus/syllabus-details/${d?.id}/`,
                                          'DELETE',
                                        ).then((d) => {
                                          getSyllabus()
                                        })
                                        let data = allSyllabus.filter(
                                          (ds, ind) => {
                                            if (ind === index) {
                                              return null
                                            } else return ds
                                          },
                                        )

                                        setAllSyllabus(data)
                                      } else {
                                        let data = allSyllabus.filter(
                                          (ds, ind) => {
                                            if (ind === index) {
                                              return null
                                            } else return ds
                                          },
                                        )

                                        setAllSyllabus(data)
                                      }
                                    }}
                                  >
                                    Yes, Do it!
                                  </button>
                                </div>
                              )
                            },
                          })
                        }}
                        size={24}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )
      )}

      {/* {!!allSyllabus.length && (
        <div className="btn-cont">
          <button className="submit-btn" onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
      )} */}
    </div>
  )
}

export default CreateSyllabus
