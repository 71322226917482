import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { MdContentCopy } from 'react-icons/md'
import Select from 'react-select'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'

const SubjectList = () => {
  const [allCourses, setAllCourses] = useState([])
  const [allStreams, setAllStreams] = useState([])
  const [allSyllabus, setAllSyllabus] = useState([])
  const [allCategory, setAllCategory] = useState([])
  const [allClasses, setAllClasses] = useState([])
  const [course, setCourse] = useState()
  const [streams, setStreams] = useState()
  const [syllabus, setSyllabus] = useState()
  const [category, setCategory] = useState()
  const [classes, setClasses] = useState()
  const [allSubjects, setAllSubjects] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!allCourses?.length) {
      getCourse()
    }

    if (!!allCourses?.length && !allStreams?.length) {
      getStreams()
    }

    if (!!allCourses?.length && !!allStreams?.length && !allSyllabus.length) {
      getSyllabus()
    }

    if (!!allSyllabus.length && !allCategory.length) {
      getCategory()
    }

    if (!!allCategory.length && !allClasses.length) {
      getClasses()
    }

    // if (!!allClasses.length) {
    //   getSubjects()
    // }

    // eslint-disable-next-line
  }, [allCourses, allStreams, allSyllabus, allCategory, allClasses])

  const getCourse = () => {
    callToBackend('courses/course-list/', 'GET').then((data) => {
      setAllCourses(data)
    })
  }

  const getStreams = () => {
    callToBackend('streams/stream-list/', 'GET').then((data) => {
      setAllStreams(data)
    })
  }

  const getSyllabus = () => {
    callToBackend('syllabus/syllabus-list/', 'GET').then((data) => {
      setAllSyllabus(data)
    })
  }

  const getCategory = () => {
    callToBackend('main-category/main-category-list/', 'GET').then((data) => {
      setAllCategory(data)
    })
  }

  const getClasses = () => {
    callToBackend('classes/class-list/', 'GET').then((data) => {
      setAllClasses(data)
      setLoading(false)
    })
  }

  const getSubjects = () => {
    setLoading(true)
    callToBackend('subjects/subject-list/', 'GET')
      .then((data) => {
        let datas = data
          ?.filter((d) => d?.Class === classes?.value)
          .map((ds) => {
            return {
              course: {
                value: ds?.Course,
                label: allCourses.find((dss) => dss?.id === ds?.Course)?.Course,
              },
              stream: {
                value: ds?.Stream,
                label: allStreams.find((dss) => dss?.id === ds?.Stream)?.Stream,
              },
              syllabus: {
                value: ds?.Stream,
                label: allSyllabus.find((dss) => dss?.id === ds?.Syllabus)
                  ?.Syllabus,
              },
              category: {
                value: ds?.Category,
                label: allCategory.find((dss) => dss?.id === ds?.Category)
                  ?.Category,
              },
              classes: {
                value: ds?.Class,
                label: allClasses.find((dss) => dss?.id === ds?.Class)?.Class,
              },
              subject: ds?.Subject,
              id: ds?.id,
              ...ds,
            }
          })

        setAllSubjects(datas)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const [copySuccess, setCopySuccess] = useState('')
  const [copiedIndex, setCopyIndex] = useState('')

  // your function to copy here

  const copyToClipBoard = async (copyMe, index) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopySuccess('Copied!')
      setCopyIndex(index)

      setTimeout(() => {
        setCopySuccess('')
        setCopyIndex(undefined)
      }, 2000)
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
  }

  return (
    <div className="admin-contents-cont">
      <div className="masters-cont">
        <div className="master-contents-cont">
          <div className="forms-cont">
            <div className="form-group">
              <Select
                options={allCourses.map((d) => {
                  return {
                    value: d?.id,
                    label: d?.Course + ' (' + d?.Course_Code + ')',
                  }
                })}
                onChange={setCourse}
                value={course}
                placeholder="Course"
              />
            </div>
            <div className="form-group">
              <Select
                options={allStreams
                  ?.filter((d) => d?.Course === course?.value)
                  ?.map((d) => {
                    return {
                      value: d?.id,
                      label: d?.Stream + ' (' + d?.Stream_Code + ')',
                    }
                  })}
                onChange={setStreams}
                value={streams}
                placeholder="Streams"
                disabled={!course}
              />
            </div>
            <div className="form-group">
              <Select
                options={allSyllabus
                  ?.filter((d) => d?.Stream === streams?.value)
                  ?.map((d) => {
                    return {
                      value: d?.id,
                      label: d?.Syllabus + ' (' + d?.Syllabus_Code + ')',
                    }
                  })}
                onChange={setSyllabus}
                value={syllabus}
                placeholder="Syllabus"
                disabled={!course}
              />
            </div>

            <div className="form-group">
              <Select
                options={allCategory
                  ?.filter((d) => d?.Syllabus === syllabus?.value)
                  ?.map((d) => {
                    return {
                      value: d?.id,
                      label: d?.Category + ' (' + d?.Category_Code + ')',
                    }
                  })}
                onChange={setCategory}
                value={category}
                placeholder="Category"
                disabled={!course}
              />
            </div>
            <div className="form-group">
              <Select
                options={allClasses
                  ?.filter((d) => d?.Category === category?.value)
                  ?.map((d) => {
                    return {
                      value: d?.id,
                      label: d?.Class + ' (' + d?.Class_Code + ')',
                    }
                  })}
                onChange={setClasses}
                value={classes}
                placeholder="Classes"
                disabled={!course}
              />
            </div>

            <button
              className="button-add"
              disabled={course?.length === 0 || streams?.length === 0}
              onClick={() => {
                getSubjects()
              }}
              style={{ width: '20rem' }}
            >
              Get Subjects
            </button>
          </div>

          {loading ? (
            <div style={{ margin: '2rem' }}>
              <Triangle
                height="80"
                width="80"
                color="var(--highlight-primary)"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            !!allSubjects?.length && (
              <>
                <div className="d-flex flex-column mt-5">
                  <span style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                    Course - {course?.label}
                    <MdContentCopy
                      onClick={() =>
                        copyToClipBoard(
                          course?.label?.split('(')[1].split(')')[0],
                          -1,
                        )
                      }
                      style={{ marginLeft: '1rem' }}
                    />
                    {copiedIndex === -1 && (
                      <span
                        style={{
                          fontSize: '1rem',
                          marginLeft: '1rem',
                          color: 'var(--highlight-green)',
                        }}
                      >
                        {copySuccess}
                      </span>
                    )}
                  </span>
                  <span style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                    Stream - {streams?.label}
                    <MdContentCopy
                      onClick={() =>
                        copyToClipBoard(
                          streams?.label?.split('(')[1].split(')')[0],
                          -2,
                        )
                      }
                      style={{ marginLeft: '1rem' }}
                    />
                    {copiedIndex === -2 && (
                      <span
                        style={{
                          fontSize: '1rem',
                          marginLeft: '1rem',
                          color: 'var(--highlight-green)',
                        }}
                      >
                        {copySuccess}
                      </span>
                    )}
                  </span>
                  <span style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                    Syllabus - {syllabus?.label}
                    <MdContentCopy
                      onClick={() =>
                        copyToClipBoard(
                          syllabus?.label?.split('(')[1].split(')')[0],
                          -3,
                        )
                      }
                      style={{ marginLeft: '1rem' }}
                    />
                    {copiedIndex === -3 && (
                      <span
                        style={{
                          fontSize: '1rem',
                          marginLeft: '1rem',
                          color: 'var(--highlight-green)',
                        }}
                      >
                        {copySuccess}
                      </span>
                    )}
                  </span>
                  <span style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                    Category - {category?.label}
                    <MdContentCopy
                      onClick={() =>
                        copyToClipBoard(
                          category?.label?.split('(')[1].split(')')[0],
                          -4,
                        )
                      }
                      style={{ marginLeft: '1rem' }}
                    />
                    {copiedIndex === -4 && (
                      <span
                        style={{
                          fontSize: '1rem',
                          marginLeft: '1rem',
                          color: 'var(--highlight-green)',
                        }}
                      >
                        {copySuccess}
                      </span>
                    )}
                  </span>
                  <span style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                    Class - {classes?.label}
                    <MdContentCopy
                      onClick={() =>
                        copyToClipBoard(
                          classes?.label?.split('(')[1].split(')')[0],
                          -5,
                        )
                      }
                      style={{ marginLeft: '1rem' }}
                    />
                    {copiedIndex === -5 && (
                      <span
                        style={{
                          fontSize: '1rem',
                          marginLeft: '1rem',
                          color: 'var(--highlight-green)',
                        }}
                      >
                        {copySuccess}
                      </span>
                    )}
                  </span>
                </div>

                <div
                  className="table-cont"
                  style={{ height: 'calc(100vh - 45rem)' }}
                >
                  <Table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Subject Name</th>
                        <th>Subject Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allSubjects.map((d, index) => (
                        <tr>
                          <td>{d?.subject}</td>
                          <td>
                            <div>
                              {d?.Subject_Code}
                              <MdContentCopy
                                onClick={() =>
                                  copyToClipBoard(d?.Subject_Code, index)
                                }
                                style={{ marginLeft: '1rem' }}
                              />
                            </div>
                            {copiedIndex === index && (
                              <span
                                style={{
                                  fontSize: '1rem',
                                  color: 'var(--highlight-green)',
                                }}
                              >
                                {copySuccess}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )
          )}

          {/* {!!allSubjects.length && (
        <div className="btn-cont">
          <button className="submit-btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )} */}
        </div>
      </div>
    </div>
  )
}

export default SubjectList
