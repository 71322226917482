import React from 'react'

const ComingSoon = () => {
  return (
    <div className="coming-soon-cont">
      <img
        src={require('../assets/images/logo.png')}
        alt=""
        className="img-1"
      />
      <span className="coming-soon-text">Coming Soon...</span>
    </div>
  )
}

export default ComingSoon
