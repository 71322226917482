import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { FormControl } from 'react-bootstrap'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import axios from 'axios'

const initialSubjectData = {
  subjectName: '',
  chapters: [
    {
      Chapter: '',
      topics: [
        {
          Topic_Name: '',
          Topic_Type: '',
          Topic_Link: '',
        },
      ],
    },
  ],
}

const chapterInitialData = {
  Chapter: '',
  topics: [
    {
      Topic_Name: '',
      Topic_Type: '',
      Topic_Link: '',
    },
  ],
}

const topicInitialData = {
  Topic_Name: '',
  Topic_Type: '',
  Topic_Link: '',
}

const CreateContent = () => {
  const [allCourses, setAllCourses] = useState([])
  const [allStreams, setAllStreams] = useState([])
  const [allSyllabus, setAllSyllabus] = useState([])
  const [allCategory, setAllCategory] = useState([])
  const [allClasses, setAllClasses] = useState([])
  const [allSubjects, setAllSubjects] = useState([])
  const [allTutors, setAllTutors] = useState([])
  const [allLanguages, setAllLanguages] = useState([])

  const [language, setLanguage] = useState()
  const [course, setCourse] = useState()
  const [streams, setStreams] = useState()
  const [syllabus, setSyllabus] = useState()
  const [category, setCategory] = useState()
  const [classes, setClasses] = useState()
  const [tutors, setTutors] = useState()
  const [loading, setLoading] = useState(false)
  const [subjectData, setSubjectData] = useState([initialSubjectData])

  const [loadingFile, setLoadinFile] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (!allCourses?.length) {
      getCourse()
    }

    if (!!allCourses?.length && !allStreams?.length) {
      getStreams()
    }

    if (!!allCourses?.length && !!allStreams?.length && !allSyllabus.length) {
      getSyllabus()
    }

    if (!!allSyllabus.length && !allCategory.length) {
      getCategory()
    }

    if (!!allCategory.length && !allClasses.length) {
      getClasses()
    }

    if (!!allClasses.length && !allSubjects.length) {
      getSubjects()
    }

    // eslint-disable-next-line
  }, [allCourses, allStreams, allSyllabus, allCategory, allClasses])

  useEffect(() => {
    getTutors()
    getLanguage()
  }, [])

  const getCourse = () => {
    callToBackend('courses/course-list/', 'GET').then((data) => {
      setAllCourses(data)
    })
  }

  const getStreams = () => {
    callToBackend('streams/stream-list/', 'GET').then((data) => {
      setAllStreams(data)
    })
  }

  const getSyllabus = () => {
    callToBackend('syllabus/syllabus-list/', 'GET').then((data) => {
      setAllSyllabus(data)
    })
  }

  const getCategory = () => {
    callToBackend('main-category/main-category-list/', 'GET').then((data) => {
      setAllCategory(data)
    })
  }

  const getClasses = () => {
    callToBackend('classes/class-list/', 'GET').then((data) => {
      setAllClasses(data)
    })
  }

  const getSubjects = () => {
    callToBackend('subjects/subject-list/', 'GET').then((data) => {
      setAllSubjects(data)
      setLoading(false)
    })
  }

  const getTutors = () => {
    callToBackend('tutors/tutor-list/', 'GET').then((data) => {
      setAllTutors(data)
    })
  }

  const getLanguage = () => {
    callToBackend('language/language-list/', 'GET').then((data) => {
      setAllLanguages(data)
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    let body = {
      language: language,
      course: course,
      stream: streams,
      syllabus: syllabus,
      class: classes,
      category: category,
      tutor: tutors,
      subjectData: subjectData,
    }

    callToBackend('content/content-list/', 'POST', body)
      .then((d) => {
        setLoading(false)
        setSubjectData([initialSubjectData])
        toast.success('Subjects created successfully')
      })
      .catch(() => {
        toast.error('Failed to create subjects.')
        setLoading(false)
      })
  }

  if (loading) {
    return (
      <div className="admin-contents-cont" style={{ margin: '2rem' }}>
        <Triangle
          height="80"
          width="80"
          color="var(--highlight-primary)"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    )
  }

  let disabled =
    !course ||
    !language ||
    !streams ||
    !category ||
    !tutors ||
    !subjectData ||
    loadingFile

  return (
    <div
      className="admin-contents-cont"
      style={{
        height: '80vh',
        overflow: 'auto',
        padding: '1rem',
        width: '100%',
      }}
    >
      <div className="form-container" style={{ width: '100%' }}>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Languages *</label>
          </div>
          <Select
            options={allLanguages?.map((d) => {
              return {
                value: d?.id,
                label: d?.Language + ' (' + d?.Language_Code + ')',
              }
            })}
            onChange={setLanguage}
            value={language}
            placeholder="Select..."
          />
        </div>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Course *</label>
          </div>
          <Select
            options={allCourses.map((d) => {
              return {
                value: d?.id,
                label: d?.Course + ' (' + d?.Course_Code + ')',
              }
            })}
            onChange={setCourse}
            value={course}
            placeholder="Select..."
          />
        </div>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Stream *</label>
          </div>
          <Select
            options={allStreams
              ?.filter((d) => d?.Course === course?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Stream + ' (' + d?.Stream_Code + ')',
                }
              })}
            onChange={setStreams}
            value={streams}
            placeholder="Select..."
            disabled={!course}
          />
        </div>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Syllabus *</label>
          </div>
          <Select
            options={allSyllabus
              ?.filter((d) => d?.Stream === streams?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Syllabus + ' (' + d?.Syllabus_Code + ')',
                }
              })}
            onChange={setSyllabus}
            value={syllabus}
            placeholder="Select..."
            disabled={!course}
          />
        </div>

        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Category *</label>
          </div>
          <Select
            options={allCategory
              ?.filter((d) => d?.Syllabus === syllabus?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Category + ' (' + d?.Category_Code + ')',
                }
              })}
            onChange={setCategory}
            value={category}
            placeholder="Select..."
            disabled={!course}
          />
        </div>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Class *</label>
          </div>
          <Select
            options={allClasses
              ?.filter((d) => d?.Category === category?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Class + ' (' + d?.Class_Code + ')',
                }
              })}
            onChange={setClasses}
            value={classes}
            placeholder="Select..."
            disabled={!course}
          />
        </div>

        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Tutor *</label>
          </div>
          <Select
            options={allTutors?.map((d) => {
              return {
                value: d?.id,
                label: d?.Tutor_Name + ' (' + d?.Tutor_Code + ')',
              }
            })}
            onChange={setTutors}
            value={tutors}
            placeholder="Select..."
          />
        </div>
      </div>

      <div className="form-container">
        <div className="form-group" style={{ width: '70%' }}>
          <div className="label-cont add-labe-cont">
            <label htmlFor="">Subjects Data *</label>
            <AiOutlinePlusCircle
              onClick={() =>
                setSubjectData(subjectData.concat(initialSubjectData))
              }
              size={24}
            />
          </div>

          {subjectData?.map((d1, index) => (
            <div className="subject-form-container">
              <div style={{ display: 'flex' }}>
                <div className="form-group">
                  <div className="label-cont">
                    <label htmlFor="">Select Subject *</label>
                  </div>

                  <Select
                    options={allSubjects
                      ?.filter((d) => d?.Class === classes?.value)
                      ?.map((d) => {
                        return { value: d?.id, label: d?.Subject }
                      })}
                    onChange={(ev) => {
                      let data = subjectData?.map((ds, ind) => {
                        if (ind === index) {
                          return {
                            ...ds,
                            subjectName: ev,
                          }
                        } else {
                          return ds
                        }
                      })
                      setSubjectData(data)
                    }}
                    value={d1?.subjectName}
                    placeholder="Select Subject"
                  />
                </div>
              </div>

              <div className="form-group">
                <div
                  style={{
                    backgroundColor: 'var(--main-bg)',
                    padding: '1rem',
                    border: '0.1rem solid hsl(0, 0%, 80%)',
                    marginBottom: '2rem',
                  }}
                >
                  <div className="label-cont add-labe-cont">
                    <label htmlFor="">Chapters *</label>
                    <AiOutlinePlusCircle
                      onClick={() => {
                        let chaptersData = d1?.chapters?.concat(
                          chapterInitialData,
                        )

                        let mainData = subjectData?.map((ds, ind) => {
                          if (ind === index) {
                            return {
                              ...ds,
                              chapters: chaptersData,
                            }
                          } else {
                            return ds
                          }
                        })
                        setSubjectData(mainData)
                      }}
                      size={24}
                    />
                  </div>

                  {d1?.chapters?.map((chapter, chapterIndex) => (
                    <div
                      style={{
                        backgroundColor: 'var(--main-bg)',
                        padding: '1rem',
                        border: '0.1rem solid hsl(0, 0%, 80%)',
                        marginBottom: '2rem',
                      }}
                    >
                      <div className="array-form-controls">
                        <div className="form-group">
                          <div className="label-cont">
                            <label htmlFor="">Chapter Name *</label>
                          </div>
                          <FormControl
                            value={chapter.Chapter}
                            onChange={(ev) => {
                              let chaptersData = d1?.chapters.map(
                                (d, index) => {
                                  if (index === chapterIndex) {
                                    return {
                                      ...d,
                                      Chapter: ev.target.value,
                                    }
                                  } else {
                                    return d
                                  }
                                },
                              )

                              let mainData = subjectData?.map((ds, ind) => {
                                if (ind === index) {
                                  return {
                                    ...ds,
                                    chapters: chaptersData,
                                  }
                                } else {
                                  return ds
                                }
                              })
                              setSubjectData(mainData)
                            }}
                          />
                        </div>
                      </div>

                      <div className="label-cont add-labe-cont">
                        <label htmlFor="">Topics *</label>
                        <AiOutlinePlusCircle
                          onClick={() => {
                            let classData = chapter?.topics?.concat(
                              topicInitialData,
                            )

                            let chaptersData = d1.chapters.map((ds, ind) => {
                              if (ind === chapterIndex) {
                                return {
                                  ...ds,
                                  topics: classData,
                                }
                              } else {
                                return ds
                              }
                            })

                            let mainData = subjectData?.map((ds, ind) => {
                              if (ind === index) {
                                return {
                                  ...ds,
                                  chapters: chaptersData,
                                }
                              } else {
                                return ds
                              }
                            })
                            setSubjectData(mainData)
                          }}
                          size={24}
                        />
                      </div>

                      <div
                        style={{
                          backgroundColor: 'var(--main-bg)',
                          padding: '1rem',
                          border: '0.1rem solid hsl(0, 0%, 80%)',
                        }}
                      >
                        {chapter?.topics.map((classe, classIndex) => (
                          <>
                            <div
                              className="array-form-controls"
                              style={{ justifyContent: 'flex-start' }}
                            >
                              <div
                                className="form-group"
                                style={{ width: '33%', marginRight: '1rem' }}
                              >
                                <div className="label-cont">
                                  <label htmlFor="">Topic Name *</label>
                                </div>
                                <FormControl
                                  value={classe.Topic_Name}
                                  onChange={(ev) => {
                                    let classData = chapter.topics.map(
                                      (ds, index) => {
                                        if (index === classIndex) {
                                          return {
                                            ...ds,
                                            Topic_Name: ev.target.value,
                                          }
                                        } else return ds
                                      },
                                    )
                                    let chaptersData = d1.chapters.map(
                                      (d, index) => {
                                        if (index === chapterIndex) {
                                          return {
                                            ...d,
                                            topics: classData,
                                          }
                                        } else {
                                          return d
                                        }
                                      },
                                    )

                                    let mainData = subjectData?.map(
                                      (ds, ind) => {
                                        if (ind === index) {
                                          return {
                                            ...ds,
                                            chapters: chaptersData,
                                          }
                                        } else {
                                          return ds
                                        }
                                      },
                                    )
                                    setSubjectData(mainData)
                                  }}
                                />
                              </div>

                              <div
                                className="form-group"
                                style={{ width: '33%', marginRight: '1rem' }}
                              >
                                <div className="label-cont">
                                  <label htmlFor="">Topic Type *</label>
                                </div>

                                <select
                                  name="topicType"
                                  id="topicsType"
                                  className="form-control"
                                  value={classe.Topic_Type}
                                  onChange={(ev) => {
                                    let classData = chapter.topics.map(
                                      (ds, index) => {
                                        if (index === classIndex) {
                                          return {
                                            ...ds,
                                            Topic_Type: ev.target.value,
                                          }
                                        } else return ds
                                      },
                                    )
                                    let chaptersData = d1.chapters.map(
                                      (d, index) => {
                                        if (index === chapterIndex) {
                                          return {
                                            ...d,
                                            topics: classData,
                                          }
                                        } else {
                                          return d
                                        }
                                      },
                                    )

                                    let mainData = subjectData?.map(
                                      (ds, ind) => {
                                        if (ind === index) {
                                          return {
                                            ...ds,
                                            chapters: chaptersData,
                                          }
                                        } else {
                                          return ds
                                        }
                                      },
                                    )
                                    setSubjectData(mainData)
                                  }}
                                >
                                  <option value="-1">Select...</option>
                                  <option value="Video">Video</option>
                                  <option value="PDF">PDF</option>
                                  <option value="Image">Image</option>
                                </select>
                              </div>

                              {classe.Topic_Type === 'Video' ? (
                                <div
                                  className="form-group"
                                  style={{ width: '33%' }}
                                >
                                  <div className="label-cont">
                                    <label htmlFor="">Topic Link *</label>
                                  </div>
                                  <FormControl
                                    value={classe.Topic_Link}
                                    onChange={(ev) => {
                                      let classData = chapter.topics.map(
                                        (ds, index) => {
                                          if (index === classIndex) {
                                            return {
                                              ...ds,
                                              Topic_Link: ev.target.value,
                                            }
                                          } else return ds
                                        },
                                      )
                                      let chaptersData = d1.chapters.map(
                                        (d, index) => {
                                          if (index === chapterIndex) {
                                            return {
                                              ...d,
                                              topics: classData,
                                            }
                                          } else {
                                            return d
                                          }
                                        },
                                      )

                                      let mainData = subjectData?.map(
                                        (ds, ind) => {
                                          if (ind === index) {
                                            return {
                                              ...ds,
                                              chapters: chaptersData,
                                            }
                                          } else {
                                            return ds
                                          }
                                        },
                                      )
                                      setSubjectData(mainData)
                                    }}
                                  />
                                </div>
                              ) : (
                                (classe.Topic_Type === 'PDF' ||
                                  classe.Topic_Type === 'Image') && (
                                  <div
                                    className="form-group"
                                    style={{ width: '33%' }}
                                  >
                                    <div className="label-cont">
                                      <label htmlFor="">Upload File *</label>
                                    </div>
                                    <FormControl
                                      id="upload"
                                      type="file"
                                      accept={
                                        classe.Topic_Type === 'PDF'
                                          ? 'application/pdf, application/vnd.ms-excel'
                                          : 'image/*'
                                      }
                                      onChange={(event) => {
                                        const url =
                                          'https://g242n.pythonanywhere.com/content/topic-file-upload/'
                                        let formData = new FormData()
                                        formData.append(
                                          'Topic_File',
                                          event.target.files[0],
                                        )
                                        const config = {
                                          headers: {
                                            'content-type':
                                              'multipart/form-data',
                                          },
                                        }
                                        axios.defaults.headers.common[
                                          'Authorization'
                                        ] = `Token ${localStorage.getItem(
                                          'user-token',
                                        )}`

                                        setLoadinFile(true)
                                        return axios
                                          .post(url, formData, config)
                                          .then((d) => {
                                            let classData = chapter.topics.map(
                                              (ds, index) => {
                                                if (index === classIndex) {
                                                  return {
                                                    ...ds,
                                                    Topic_Link:
                                                      d?.data?.Topic_File,
                                                  }
                                                } else return ds
                                              },
                                            )
                                            let chaptersData = d1.chapters.map(
                                              (d, index) => {
                                                if (index === chapterIndex) {
                                                  return {
                                                    ...d,
                                                    topics: classData,
                                                  }
                                                } else {
                                                  return d
                                                }
                                              },
                                            )

                                            let mainData = subjectData?.map(
                                              (ds, ind) => {
                                                if (ind === index) {
                                                  return {
                                                    ...ds,
                                                    chapters: chaptersData,
                                                  }
                                                } else {
                                                  return ds
                                                }
                                              },
                                            )
                                            setSubjectData(mainData)
                                            setLoadinFile(false)
                                          })
                                      }}
                                      onClick={(event) => {
                                        event.target.value = null
                                      }}
                                    />
                                    {loadingFile && (
                                      <span>File uploading... please wait</span>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="btn-cont">
        <button
          className="submit-btn"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default CreateContent
