import React from 'react'
import { Routes, Route } from 'react-router-dom'
import MainIndex from '../MainIndex'
import ProtectedRoute from '../utils/ProtectedNav'
import Auth from '../views/Auth/Auth'
import { routes } from './Routes'

const Navigations = () => {
  return (
    <Routes>
      <Route path="/auth" element={<Auth />}>
        <Route path="login" element={<Auth />} />
        <Route path="register" element={<Auth />} />
      </Route>
      {routes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          element={
            <ProtectedRoute>
              <route.element />
            </ProtectedRoute>
          }
        />
      ))}
      <Route path="/*" element={<MainIndex />} />
    </Routes>
  )
}

export default Navigations
