import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import CreateMasters from './Create-Masters'
import Header from '../../components/Header'
import CreateLanguages from './CreateLanguages'
import CreateTutors from './CreateTutors'
import CreateContentIndex from './CreateContentIndex'
import { isAdmin } from '../Home/Home'
import { useNavigate } from 'react-router-dom'
import SubjectList from './SubjectList'

const Admin = () => {
  const [selectedTab, setSelectedTab] = useState('Create-Masters')
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAdmin) {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header />
      <div className="admin-cont">
        <div className="left-cont">
          <span
            onClick={() => setSelectedTab('Create-Masters')}
            className={clsx(
              selectedTab === 'Create-Masters' ? 'active-span' : '',
            )}
          >
            Create Masters
          </span>
          <span
            onClick={() => setSelectedTab('Create-Content')}
            className={clsx(
              selectedTab === 'Create-Content' ? 'active-span' : '',
            )}
          >
            Create Contents
          </span>
          <span
            onClick={() => setSelectedTab('Create-Languages')}
            className={clsx(
              selectedTab === 'Create-Languages' ? 'active-span' : '',
            )}
          >
            Create Languages
          </span>
          <span
            onClick={() => setSelectedTab('Create-Tutors')}
            className={clsx(
              selectedTab === 'Create-Tutors' ? 'active-span' : '',
            )}
          >
            Create Tutors
          </span>
          <span
            onClick={() => setSelectedTab('Subjects')}
            className={clsx(selectedTab === 'Subjects' ? 'active-span' : '')}
          >
            Subjects
          </span>
        </div>
        {selectedTab === 'Create-Masters' && <CreateMasters />}
        {selectedTab === 'Subjects' && <SubjectList />}
        {selectedTab === 'Create-Content' && <CreateContentIndex />}
        {selectedTab === 'Create-Languages' && <CreateLanguages />}
        {selectedTab === 'Create-Tutors' && <CreateTutors />}
      </div>
    </>
  )
}

export default Admin
