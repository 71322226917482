import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import { Spiral as Hamburger } from 'hamburger-react'
import { useMatchedRoute } from '../utils/UseMatchParams'
import { HiOutlineFilter } from 'react-icons/hi'
import { FormControl } from 'react-bootstrap'
import { IoCloseSharp, IoSearchSharp } from 'react-icons/io5'
import { callToBackend } from '../utils/api'
import { useNavigate } from 'react-router-dom'
import { Student_Name, isAdmin } from '../views/Home/Home'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { confirmAlert } from 'react-confirm-alert'
import { Triangle } from 'react-loader-spinner'

export default function Header(props) {
  const {
    course,
    streams,
    syllabus,
    language,
    scrolledPosition,
    isConfigureScreen,
    showChapterName,
    chapterName,
  } = props
  const [isNavVisible, setNavVisibility] = useState(false)
  const [isAcive, setIsActiveTab] = useState('')
  const { setShow } = props
  const [searchKey, setSearchKey] = useState('')
  const [openDropDown, setOpenDropdown] = useState(false)
  const [searchedData, setSearchedData] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)')
    mediaQuery.addListener(handleMediaQueryChange)
    handleMediaQueryChange(mediaQuery)

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      // setIsSmallScreen(true)
    } else {
      // setIsSmallScreen(false)
    }
  }

  const handleSearch = (searchKey) => {
    setLoading(true)
    callToBackend(
      `content/topic-search/?search=${searchKey}&language=${language?.value}&course=${course?.value}&stream=${streams?.value}&syllabus=${syllabus?.value}`,
      'GET',
    ).then((d) => {
      setLoading(false)
      setSearchedData(d)
    })
  }

  const handleConmfirmGoBack = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to go back to main screen?</p>
            <button onClick={onClose} className="cancel-btn">
              No
            </button>
            <button
              className="submit-btn"
              onClick={() => {
                onClose()
                navigate(-1)
              }}
            >
              Yes, Go!
            </button>
          </div>
        )
      },
    })
  }

  const route = useMatchedRoute()
  const pathName = route?.name

  return (
    <header
      className={clsx(
        'Header',
        scrolledPosition > 300 && !isNavVisible
          ? 'Header-scrolled'
          : isNavVisible
          ? 'navOpened'
          : '',
      )}
    >
      <div className="Logo">
        {pathName !== 'Chapters' ? (
          <a href="/" onClick={() => setNavVisibility(false)}>
            <img
              src={require('../assets/images/logo-crop.png')}
              alt="logo"
              className="img1"
            />
          </a>
        ) : (
          <div onClick={() => setNavVisibility(false)}>
            <img
              src={require('../assets/images/logo-crop.png')}
              alt="logo"
              className="img1"
            />
          </div>
        )}

        <div className="slogan">
          <img src={require('../assets/images/slogan.png')} alt="logo" />
        </div>
      </div>

      {showChapterName && pathName === 'Chapters' && !isConfigureScreen && (
        <div className="chapter-name">
          <span
            style={{
              fontSize: '1.2rem',
              fontWeight: 600,
              color: 'var(--gradient-2)',
              cursor: 'pointer',
              marginRight: '2rem',
            }}
            onClick={() => handleConmfirmGoBack()}
          >
            <IoArrowBackCircleOutline
              style={{ marginRight: '1rem', fontSize: '3rem' }}
            />
            Go Back
          </span>
          <span className="chapter-name">{chapterName}</span>
        </div>
      )}

      {pathName === 'Home' && !isConfigureScreen && (
        <div className="search-filter-cont">
          <div className="form-group">
            <FormControl
              type="text"
              placeholder="Search by chapters or topics"
              onClick={() => setOpenDropdown(true)}
              onChange={(ev) => {
                setSearchKey(ev.target.value)
                if (ev.target.value.length > 4) {
                  handleSearch(ev.target.value)
                }
              }}
              value={searchKey}
            />
            {openDropDown ? (
              <IoCloseSharp
                className="close-icon"
                onClick={() => {
                  setOpenDropdown(false)
                  setSearchKey('')
                  setSearchedData([])
                }}
              />
            ) : (
              <IoSearchSharp
                className="close-icon"
                onClick={() => setOpenDropdown(true)}
              />
            )}
            {openDropDown && (
              <div className="drop-down-cont">
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Triangle
                      height="60"
                      width="60"
                      color="var(--highlight-primary)"
                      ariaLabel="triangle-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : !!searchedData?.length ? (
                  searchedData.map((data) => (
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        navigate(
                          `/content/${data?.Chapter?.Chapter}/${data?.Chapter?.id}`,
                        )
                      }}
                    >
                      <div className="span">
                        Stream -{' '}
                        <div className="search-highlight">
                          {data?.Chapter?.Content_Id?.Stream?.Stream}
                        </div>
                      </div>
                      <div className="span">
                        Class -{' '}
                        <div className="search-highlight">
                          {data?.Chapter?.Content_Id?.Class?.Class}
                        </div>
                      </div>
                      <div className="span">
                        Subject -{' '}
                        <div className="search-highlight">
                          {data?.Chapter?.Content_Id?.Subject?.Subject}
                        </div>
                      </div>
                      <div className="span">
                        Tutor -{' '}
                        <div className="search-highlight">
                          {data?.Chapter?.Content_Id?.Tutor?.Tutor_Name}
                        </div>
                      </div>
                      <div className="span">
                        Chapter -{' '}
                        <div className="search-highlight">
                          {data?.Chapter?.Chapter}
                        </div>
                      </div>
                      <div className="span">
                        Topic -{' '}
                        <div className="search-highlight">
                          {data?.Topic_Name}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span>No Data Found</span>
                )}
              </div>
            )}
          </div>
          <button className="filter-btn" onClick={() => setShow(true)}>
            <HiOutlineFilter style={{ marginRight: '1rem' }} />
            Filter
          </button>
        </div>
      )}

      <CSSTransition
        in={isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        <>
          {isNavVisible && (
            <nav className="Nav">
              <div className="nav-items-cont">
                <a
                  href="/profile"
                  className={clsx(isAcive === 'about-us' ? 'active' : '')}
                  onClick={() => {
                    setIsActiveTab('about-us')
                    setNavVisibility(false)
                  }}
                >
                  Profile
                </a>
                {isAdmin && (
                  <a
                    href="/admin"
                    className={clsx(isAcive === 'about-us' ? 'active' : '')}
                    onClick={() => {
                      setIsActiveTab('about-us')
                      setNavVisibility(false)
                    }}
                  >
                    Admin
                  </a>
                )}
              </div>
            </nav>
          )}
        </>
      </CSSTransition>

      <div
        style={{
          height: '4rem',
          width: '4rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'var(--highlight-primary)',
          borderRadius: '100%',
          marginLeft: 'auto',
          marginRight: '2rem',
        }}
      >
        <span
          style={{
            color: 'var(--text-white)',
            textTransform: 'uppercase',
            fontSize: '2rem',
          }}
        >
          {Student_Name?.slice(0, 2)}
        </span>
      </div>
      <Hamburger
        color="var(--text-primary)"
        onToggle={() => setNavVisibility(!isNavVisible)}
        distance="sm"
        size={50}
        rounded={false}
        barHeight={10}
        className="hamburger"
        toggled={isNavVisible}
      />
    </header>
  )
}
