import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { FormControl } from 'react-bootstrap'
import axios from 'axios'

const CreateContentFile = () => {
  const [allCourses, setAllCourses] = useState([])
  const [allStreams, setAllStreams] = useState([])
  const [allSyllabus, setAllSyllabus] = useState([])
  const [allLanguages, setAllLanguages] = useState([])

  const [language, setLanguage] = useState()
  const [course, setCourse] = useState()
  const [streams, setStreams] = useState()

  const [syllabus, setSyllabus] = useState()
  const [loading, setLoading] = useState(false)

  const [file, setFile] = useState()

  useEffect(() => {
    if (!allCourses?.length) {
      getCourse()
    }

    if (!!allCourses?.length && !allStreams?.length) {
      getStreams()
    }

    if (!!allCourses?.length && !!allStreams?.length && !allSyllabus.length) {
      getSyllabus()
    }

    // eslint-disable-next-line
  }, [allCourses, allStreams, allSyllabus])

  useEffect(() => {
    getLanguage()
  }, [])

  const getCourse = () => {
    callToBackend('courses/course-list/', 'GET').then((data) => {
      setAllCourses(data)
    })
  }

  const getStreams = () => {
    callToBackend('streams/stream-list/', 'GET').then((data) => {
      setAllStreams(data)
    })
  }

  const getSyllabus = () => {
    callToBackend('syllabus/syllabus-list/', 'GET').then((data) => {
      setAllSyllabus(data)
      setLoading(false)
    })
  }

  const getLanguage = () => {
    callToBackend('language/language-list/', 'GET').then((data) => {
      setAllLanguages(data)
    })
  }

  const handleSubmit = async () => {
    setLoading(true)

    const url = 'https://g242n.pythonanywhere.com/content/bulk-upload/'
    let formData = new FormData()
    formData.append('Language', language?.value)
    formData.append('Course', course?.value)
    formData.append('Stream', streams?.value)
    formData.append('Syllabus', syllabus?.value)
    formData.append('Contents', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.defaults.headers.common[
      'Authorization'
    ] = `Token ${localStorage.getItem('user-token')}`

    return axios
      .post(url, formData, config)
      .then(() => {
        setLoading(false)
        toast.success('Subjects created successfully')
      })
      .catch((e) => {
        toast.error(e?.response?.data?.Error)
        setLoading(false)
      })
  }

  if (loading) {
    return (
      <div className="admin-contents-cont" style={{ margin: '2rem' }}>
        <Triangle
          height="80"
          width="80"
          color="var(--highlight-primary)"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    )
  }

  let disabled = !course || !language || !file

  return (
    <div
      className="admin-contents-cont"
      style={{
        height: '60vh',
        overflow: 'auto',
        padding: '1rem',
        width: '100%',
      }}
    >
      <div className="form-container" style={{ width: '100%' }}>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Languages *</label>
          </div>
          <Select
            options={allLanguages?.map((d) => {
              return {
                value: d?.id,
                label: d?.Language + ' (' + d?.Language_Code + ')',
              }
            })}
            onChange={setLanguage}
            value={language}
            placeholder="Select..."
          />
        </div>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Course *</label>
          </div>
          <Select
            options={allCourses.map((d) => {
              return {
                value: d?.id,
                label: d?.Course + ' (' + d?.Course_Code + ')',
              }
            })}
            onChange={setCourse}
            value={course}
            placeholder="Select..."
          />
        </div>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Stream *</label>
          </div>
          <Select
            options={allStreams
              ?.filter((d) => d?.Course === course?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Stream + ' (' + d?.Stream_Code + ')',
                }
              })}
            onChange={setStreams}
            value={streams}
            placeholder="Select..."
            disabled={!course}
          />
        </div>
        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Syllabus *</label>
          </div>
          <Select
            options={allSyllabus
              ?.filter((d) => d?.Stream === streams?.value)
              ?.map((d) => {
                return {
                  value: d?.id,
                  label: d?.Syllabus + ' (' + d?.Syllabus_Code + ')',
                }
              })}
            onChange={setSyllabus}
            value={syllabus}
            placeholder="Select..."
            disabled={!course}
          />
        </div>

        <div className="form-group">
          <div className="label-cont">
            <label htmlFor="">Choose Subject File *</label>
          </div>
          <FormControl
            id="upload"
            type="file"
            accept="csv"
            onChange={(event) => {
              setFile(event.target.files[0])
            }}
            onClick={(event) => {
              event.target.value = null
            }}
          />
        </div>
      </div>

      <div className="btn-cont">
        <button
          className="submit-btn"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default CreateContentFile
