import React, { useEffect, useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { callToBackend } from '../../utils/api'
import { Triangle } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import axios from 'axios'

const chapterInitialData = {
  Chapter: '',
  topics: [
    {
      Topic_Name: '',
      Topic_Type: '',
      Topic_Link: '',
    },
  ],
}

const topicInitialData = {
  Topic_Name: '',
  Topic_Type: '',
  Topic_Link: '',
  id: null,
}

const EditChapter = (props) => {
  const { selectedChapters, onClose } = props
  const [loading, setLoading] = useState(false)
  const [subjectData, setSubjectData] = useState([chapterInitialData])

  useEffect(() => {
    setLoading(true)
    callToBackend(`content/topic-list/${selectedChapters?.Chapter__id}/`).then(
      (data) => {
        let d = {
          Chapter: selectedChapters?.Chapter__Chapter,
          topics: data.map((ds) => {
            return {
              Topic_Name: ds?.Topic_Name,
              Topic_Type: ds?.Topic_Type,
              Topic_Link: ds?.Topic_Link,
              id: ds?.id,
            }
          }),
        }

        setSubjectData([d])
        setLoading(false)
      },
    )
    // eslint-disable-next-line
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    let body = {
      subjectData: subjectData?.map((d) => {
        return {
          id: selectedChapters?.Chapter__id,
          Chapter: d.Chapter,
          isActive: d?.isActive,
          Topics: d.topics
            .filter((d) => d?.id !== null)
            .map((dss) => {
              return {
                ...dss,
                Chapter: selectedChapters?.Chapter__id,
                isActive: dss?.isActive,
              }
            }),
        }
      })[0],
    }

    callToBackend(`content/chapter-details/`, 'PUT', body)
      .then(() => {
        let body2 = subjectData?.map((d) => {
          return d.topics
            .filter((d) => d?.id === null)
            .map((dss) => {
              return {
                ...dss,
                Chapter: selectedChapters?.Chapter__id,
              }
            })
        })[0]

        callToBackend(`content/topic-list/0/`, 'POST', body2)
          .then(() => {
            setLoading(false)
            onClose()
            toast.success('Successfuly updated chapters')
          })
          .catch((d) => setLoading(false))
      })
      .catch((d) => setLoading(false))
  }

  if (loading) {
    return (
      <div className="admin-contents-cont" style={{ margin: '2rem' }}>
        <Triangle
          height="80"
          width="80"
          color="var(--highlight-primary)"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    )
  }

  let disabled = !subjectData

  return (
    <div
      className="admin-contents-cont"
      style={{ width: '100%', padding: '0' }}
    >
      <div
        className="form-container"
        style={{
          width: '100%',
          padding: '0',
          overflow: 'auto',
        }}
      >
        <div
          className="form-group"
          style={{ width: '100%', marginRight: '0', marginBottom: '0' }}
        >
          {subjectData?.map((chapter, chapterIndex) => (
            <div
              style={{
                backgroundColor: 'var(--main-bg)',
                padding: '1rem',
                border: '0.1rem solid hsl(0, 0%, 80%)',
              }}
            >
              <div
                className="array-form-controls"
                style={{ marginBottom: '2rem' }}
              >
                <div className="form-group" style={{ width: '80%' }}>
                  <div className="label-cont">
                    <label htmlFor="">Chapter Name *</label>
                  </div>
                  <FormControl
                    type="text"
                    value={chapter?.Chapter}
                    onChange={(ev) => {
                      let chaptersData = subjectData?.map((ds, ind) => {
                        if (ind === chapterIndex) {
                          return {
                            ...ds,
                            Chapter: ev.target.value,
                          }
                        } else {
                          return ds
                        }
                      })

                      setSubjectData(chaptersData)
                    }}
                  />
                </div>
              </div>

              <div className="label-cont add-labe-cont">
                <label htmlFor="">Topics *</label>
                <AiOutlinePlusCircle
                  onClick={() => {
                    let classData = chapter?.topics?.concat(topicInitialData)

                    let chaptersData = subjectData?.map((ds, ind) => {
                      if (ind === chapterIndex) {
                        return {
                          ...ds,
                          topics: classData,
                        }
                      } else {
                        return ds
                      }
                    })

                    setSubjectData(chaptersData)
                  }}
                  size={24}
                />
              </div>

              <div
                style={{
                  backgroundColor: 'var(--main-bg)',
                  padding: '1rem',
                  border: '0.1rem solid hsl(0, 0%, 80%)',
                }}
              >
                {chapter?.topics.map((topic, classIndex) => (
                  <>
                    <div className="array-form-controls">
                      <div className="form-group" style={{ width: '33%' }}>
                        <div className="label-cont">
                          <label htmlFor="">Topic Name *</label>
                        </div>
                        <FormControl
                          value={topic?.Topic_Name}
                          onChange={(ev) => {
                            let classData = chapter.topics.map((ds, index) => {
                              if (index === classIndex) {
                                return {
                                  ...ds,
                                  Topic_Name: ev.target.value,
                                }
                              } else return ds
                            })
                            let chaptersData = subjectData?.map((d, index) => {
                              if (index === chapterIndex) {
                                return {
                                  ...d,
                                  topics: classData,
                                }
                              } else {
                                return d
                              }
                            })

                            setSubjectData(chaptersData)
                          }}
                        />
                      </div>

                      <div className="form-group" style={{ width: '33%' }}>
                        <div className="label-cont">
                          <label htmlFor="">Topic Type *</label>
                        </div>

                        <select
                          name="topicType"
                          id="topicsType"
                          className="form-control"
                          value={topic?.Topic_Type}
                          onChange={(ev) => {
                            let classData = chapter.topics.map((ds, index) => {
                              if (index === classIndex) {
                                return {
                                  ...ds,
                                  Topic_Type: ev.target.value,
                                }
                              } else return ds
                            })
                            let chaptersData = subjectData?.map((d, index) => {
                              if (index === chapterIndex) {
                                return {
                                  ...d,
                                  topics: classData,
                                }
                              } else {
                                return d
                              }
                            })

                            setSubjectData(chaptersData)
                          }}
                        >
                          <option value="-1">Select...</option>
                          <option value="Video">Video</option>
                          <option value="PDF">PDF</option>
                          <option value="Image">Image</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ width: '33%' }}>
                        {topic?.Topic_Type === 'Video' ? (
                          <div>
                            <div className="label-cont">
                              <label htmlFor="">Topic Link *</label>
                            </div>
                            <FormControl
                              value={topic?.Topic_Link}
                              onChange={(ev) => {
                                let classData = chapter.topics.map(
                                  (ds, index) => {
                                    if (index === classIndex) {
                                      return {
                                        ...ds,
                                        Topic_Link: ev.target.value,
                                      }
                                    } else return ds
                                  },
                                )
                                let chaptersData = subjectData?.map(
                                  (d, index) => {
                                    if (index === chapterIndex) {
                                      return {
                                        ...d,
                                        topics: classData,
                                      }
                                    } else {
                                      return d
                                    }
                                  },
                                )

                                setSubjectData(chaptersData)
                              }}
                            />
                          </div>
                        ) : (
                          (topic?.Topic_Type === 'PDF' ||
                            topic?.Topic_Type === 'Image') && (
                            <div>
                              <div className="label-cont">
                                <label htmlFor="">Upload File *</label>
                              </div>
                              <FormControl
                                id="upload"
                                type="file"
                                accept={
                                  topic?.Topic_Type === 'PDF'
                                    ? 'application/pdf, application/vnd.ms-excel'
                                    : 'image/*'
                                }
                                onChange={(event) => {
                                  const url =
                                    'https://g242n.pythonanywhere.com/content/topic-file-upload/'
                                  let formData = new FormData()
                                  formData.append(
                                    'Topic_File',
                                    event.target.files[0],
                                  )
                                  const config = {
                                    headers: {
                                      'content-type': 'multipart/form-data',
                                    },
                                  }
                                  axios.defaults.headers.common[
                                    'Authorization'
                                  ] = `Token ${localStorage.getItem(
                                    'user-token',
                                  )}`
                                  return axios
                                    .post(url, formData, config)
                                    .then((d) => {
                                      let classData = chapter.topics.map(
                                        (ds, index) => {
                                          if (index === classIndex) {
                                            return {
                                              ...ds,
                                              Topic_Link: d?.data?.Topic_File,
                                            }
                                          } else return ds
                                        },
                                      )

                                      let chaptersData = subjectData?.map(
                                        (d, index) => {
                                          if (index === chapterIndex) {
                                            return {
                                              ...d,
                                              topics: classData,
                                            }
                                          } else {
                                            return d
                                          }
                                        },
                                      )

                                      setSubjectData(chaptersData)
                                    })
                                }}
                                onClick={(event) => {
                                  event.target.value = null
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="btn-cont d-flex justify-content-center">
        <button
          className="submit-btn"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Submit
        </button>

        <button
          className="submit-btn ms-3"
          onClick={onClose}
          disabled={disabled}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default EditChapter
