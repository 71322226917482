import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../components/Header'
import { callToBackend } from '../../utils/api'
import clsx from 'clsx'
import { Triangle } from 'react-loader-spinner'
import Select from 'react-select'
import { MdOutlineTopic } from 'react-icons/md'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { isAdmin } from './Home'
import { MdDeleteOutline } from 'react-icons/md'
import ReactPlayer from 'react-player'
import { FormControl } from 'react-bootstrap'

const HomeContents = () => {
  let { chapterName, unique_chapter_id } = useParams()
  const [loading, setLoading] = useState()
  const [topics, setTopics] = useState()
  const [selectedTopics, setSelectedTopics] = useState()
  const [classs, setClasss] = useState()
  const [searchKey, setSearchKey] = useState('')

  useEffect(() => {
    setLoading(true)
    callToBackend(`content/topic-list/${unique_chapter_id}/`).then((data) => {
      setTopics(data)
      setSelectedTopics(data[0])
      setLoading(false)
      setClasss({ value: data[0]?.id, label: data[0]?.Topic_Name })
    })
    // eslint-disable-next-line
  }, [])

  const handleConmfirmDelete = (ev, item) => {
    ev.stopPropagation()
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete the selected item?</p>
            <button onClick={onClose} className="cancel-btn">
              No
            </button>
            <button
              className="submit-btn"
              onClick={() => {
                handleChapterDelete(item)
                onClose()
              }}
            >
              Yes, Do it!
            </button>
          </div>
        )
      },
    })
  }

  const handleChapterDelete = (chap) => {
    let body = {
      isActive: false,
      id: chap?.id,
    }
    callToBackend(`content/topic-details/`, 'PATCH', body).then(() => {
      window.location.reload()
    })
  }

  var url = selectedTopics?.Topic_Link
  var videoId = url?.split('v=')[1]?.split('&')[0]

  return (
    <>
      <Header showChapterName={true} chapterName={chapterName} />
      {loading && (
        <div
          style={{
            height: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Triangle
            height="180"
            width="180"
            color="var(--highlight-primary)"
            ariaLabel="triangle-loading"
            visible={true}
          />
        </div>
      )}
      {loading !== undefined && !loading && (
        <div className="home-cont home-cont-topics" id="main">
          <div
            className="home-contents-cont"
            style={{ flexDirection: 'row-reverse' }}
          >
            <div className="contents-left">
              <div className="cards-cont" style={{ height: '93%' }}>
                <div className="search-cont" style={{ width: '100%' }}>
                  <FormControl
                    type="text"
                    placeholder="Search Topics..."
                    onChange={(ev) => {
                      setSearchKey(ev.target.value)
                    }}
                    value={searchKey}
                  />
                </div>
                {topics
                  ?.filter((d) =>
                    searchKey?.length > 0
                      ? d?.Topic_Name?.toLowerCase()?.includes(
                          searchKey?.toLowerCase(),
                        )
                      : d,
                  )
                  ?.map((d) => (
                    <div
                      className={clsx('cards ', {
                        'active-cards': d?.id === selectedTopics?.id,
                      })}
                      onClick={() => setSelectedTopics(d)}
                    >
                      <div className="cards-title">
                        <MdOutlineTopic className="title-icon" />
                        <span>{d?.Topic_Name}</span>
                        {isAdmin && (
                          <MdDeleteOutline
                            onClick={(ev) =>
                              handleConmfirmDelete(ev, d, 'subject')
                            }
                            className="title-icon"
                            style={{ marginRight: '0', marginLeft: 'auto' }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div
              className="contents-right contents-right-video"
              style={{ padding: '1rem' }}
            >
              <div className="class-select-cont">
                <div className="chapter-name">{chapterName}</div>
                <Select
                  options={topics?.map((d) => {
                    return { value: d?.id, label: d?.Topic_Name }
                  })}
                  onChange={(ev) => {
                    setClasss(ev)
                    setSelectedTopics(topics?.find((d) => d?.id === ev?.value))
                  }}
                  value={classs}
                  placeholder="Select Topics"
                />
              </div>
              {selectedTopics &&
              selectedTopics?.Topic_Type?.toUpperCase() === 'VIDEO' ? (
                <div className="video-player-cont">
                  <ReactPlayer
                    url={`https://www.youtube.com/embed/${videoId}?autoplay=0&origin=http://example.com&controls=0&rel=1`}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
              ) : selectedTopics?.Topic_Type?.toUpperCase() === 'PDF' ? (
                <iframe
                  src={`${selectedTopics?.Topic_Link}#view=fitH`}
                  title="testPdf"
                  className="pdf-iframe"
                />
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${selectedTopics?.Topic_Link})`,
                  }}
                  className="image-cont-link"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HomeContents
