import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const MainIndex = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [navigate])

  return (
    <div className="d-flex justify-content-center align-items-center w-100 notfound-cont" />
  )
}

export default MainIndex
