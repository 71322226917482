import ComingSoon from '../components/ComingSoon'
import Admin from '../views/Admin/Admin'
import Home from '../views/Home/Home'
import HomeContents from '../views/Home/HomeContents'
import Profile from '../views/Profile/Profile'

export const routes = [
  { name: 'Home', path: '/', element: Home },
  { name: 'Home', path: '/home/:classId/:catId/:subId/:tutId', element: Home },
  {
    name: 'Chapters',
    path: '/content/:chapterName/:unique_chapter_id',
    element: HomeContents,
  },
  { name: 'Profile', path: '/profile', element: Profile },
  { name: 'Admin', path: '/admin', element: Admin },
  { name: 'ComingSoon', path: '/coming-soon', element: ComingSoon },
]
