import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'animate.css/animate.min.css'
import SplashScreen from './components/SplashScreen'
import Navigations from './navigation/Navigations'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const myRef = React.createRef()
  const [loadSplashScreen, setLoadSplashScreen] = useState(true)
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setLoadSplashScreen(false)
    }, 1500)
  }, [])

  const onScroll = () => {
    const scrollTop = myRef.current.scrollTop
    setScroll(scrollTop)
  }

  return (
    <div className="body--dark" style={{ height: '100%' }}>
      <ToastContainer position="top-center" />
      {loadSplashScreen ? (
        <SplashScreen />
      ) : (
        <BrowserRouter>
          <div className="app-routes" onScroll={onScroll} ref={myRef}>
            <Navigations scroll={scroll} />
          </div>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
