import clsx from 'clsx'
import React, { useState } from 'react'
import CreateContent from './CreateContent'
import CreateContentFile from './CreateContentFile'

const masters = ['Create Content', 'Create Content File']

const CreateContentIndex = () => {
  const [selectedCategory, setSelectedCategory] = useState('Create Content')

  return (
    <div className="admin-contents-cont">
      <div className="masters-cont">
        <div className="tabs-cont">
          {masters?.map((d) => (
            <div
              onClick={() => setSelectedCategory(d)}
              className={clsx(
                'tabs',
                selectedCategory === d ? 'active-tabs' : '',
              )}
            >
              {d}
            </div>
          ))}
        </div>

        {selectedCategory === 'Create Content' && <CreateContent />}
        {selectedCategory === 'Create Content File' && <CreateContentFile />}
      </div>
    </div>
  )
}

export default CreateContentIndex
