import { matchPath, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { routes } from '../navigation/Routes'

export function useMatchedRoute() {
  const { pathname } = useLocation()
  for (const route of routes) {
    if (matchPath({ path: route.path }, pathname)) {
      return route
    }
  }
}

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() }
    return <Children {...props} match={match} />
  }
}
